import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter'

interface GetInitialDataApiResponse {
  countries: {
    id: number
    code: string
    name: string
    default_currency: string
    available_for_signup: number
    phone_code: string
  }[]
  currencies: {
    id: number
    code: string
    name: string
    decimal_places: number
  }[]
  g_site_key: string
  configs?: {
    FFF_EXCHANGE: string
    FFF_PIASTRIX: string
  }
}

export interface Country {
  id: number
  code: string
  name: string
  defaultCurrency: string
  availableForSignup: number
  phoneCode: string
}

export interface Currency {
  id: number
  code: string
  name: string
  decimalPlaces: number
}

type GetInitialDatResponse = {
  countries: Country[]
  currencies: Currency[]
  gSiteKey: string
  configs?: {
    FFFEXCHANGE: string
    FFFPIASTRIX: string
  }
}

export const getInitialData = async (): Promise<GetInitialDatResponse> => {
  const response = await httpClient.get<
    GetInitialDataApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<GetInitialDataApiResponse>>
  >('/api/init')

  // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data.data
  ) as GetInitialDatResponse
  return convertedToCamelCaseResponse
}
