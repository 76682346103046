<template>
  <div
    :class="`relative w-full z-1001 h-[${MOBILE_BOTTOM_NAVBAR_HEIGHT}] bg-ne-bg-3`"
  >
    <div class="flex flex-row justify-between px-4 items-center">
      <div
        v-for="navButton of navigation"
        :key="navButton.name"
        class="touch-manipulation w-full"
        @click="navButton.handler"
      >
        <component :is="navButton.component" v-bind="navButton" />
      </div>
    </div>
  </div>
  <MobileDepositPanel />
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, nextTick, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { RoutesNames } from '@/router'
import { MOBILE_BOTTOM_NAVBAR_HEIGHT } from '@/layouts/DefaultLayout/MobileLayout/MobileBottomNavBar/MOBILE_BOTTOM_NAVBAR_HEIGHT'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/auth'
import { NavigationElement } from '@/layouts/DefaultLayout/MobileLayout/types'
import MobileDepositPanel from '@/components/mobile/MobileDepositPanel/MobileDepositPanel.vue'
import { useMobileLayoutStore } from '@/stores/mobileLayout'
import { storeToRefs } from 'pinia'
import { useAppStateStore } from '@/stores/appStateStore'

const router = useRouter()
const { isSignedIn } = useAuthStore()

const { t } = useI18n()

const {
  isMobileDepositPanelOpen,
  isMobileProfilePanelOpen,
  isMobileAuthPanelOpen,
  isMobileMainMenuOpen,
  globalSearchRef,
  isGlobalSearchFocused,
  authPanelTabIndex,
} = storeToRefs(useMobileLayoutStore())

const { isQuickDepositDialogOpen } = storeToRefs(useAppStateStore())

const MobileBaseNavButton = defineAsyncComponent(
  () =>
    import(
      '@/layouts/DefaultLayout/MobileLayout/MobileBottomNavBar/MobileBaseNavButton.vue'
    )
)

const MobileDepositNavButton = defineAsyncComponent(
  () =>
    import(
      '@/layouts/DefaultLayout/MobileLayout/MobileBottomNavBar/MobileDepositNavButton.vue'
    )
)

const navigation = computed<NavigationElement[]>(() => {
  return [
    {
      name: t('mobile-bottom-navbar.menu'),
      icon: 'mobile-bottom-navbar-menu',
      component: MobileBaseNavButton,
      active: isMobileMainMenuOpen.value && !isGlobalSearchFocused.value,
      handler() {
        isMobileMainMenuOpen.value = !isMobileMainMenuOpen.value
        isMobileDepositPanelOpen.value = false
      },
    },
    {
      name: t('mobile-bottom-navbar.games'),
      icon: 'mobile-bottom-navbar-games',
      component: MobileBaseNavButton,
      active:
        router.currentRoute.value.name === RoutesNames.Index &&
        !isMobileMainMenuOpen.value &&
        !isMobileDepositPanelOpen.value,
      async handler() {
        await router.push({ name: RoutesNames.Index })
        isMobileMainMenuOpen.value = false
        isMobileProfilePanelOpen.value = false
        isMobileDepositPanelOpen.value = false
        requestAnimationFrame(() => {
          const GAMES_CATALOG = document.querySelector('#games-catalog')
          GAMES_CATALOG?.scrollIntoView({ behavior: 'auto' })
        })
      },
    },
    {
      name: t('mobile-bottom-navbar.deposit'),
      icon: 'mobile-bottom-navbar-deposit',
      component: MobileDepositNavButton,
      active: isMobileDepositPanelOpen.value,
      handler() {
        if (!isSignedIn) {
          isMobileAuthPanelOpen.value = true
          authPanelTabIndex.value = 0
        } else {
          isQuickDepositDialogOpen.value = true
        }

        isMobileMainMenuOpen.value = false
        isMobileProfilePanelOpen.value = false
      },
    },
    {
      name: t('mobile-bottom-navbar.wallet'),
      icon: 'mobile-bottom-navbar-wallet',
      active:
        router.currentRoute.value.name === RoutesNames.ProfileWalletBalances &&
        !isMobileMainMenuOpen.value &&
        !isMobileDepositPanelOpen.value,
      component: MobileBaseNavButton,
      async handler() {
        if (!isSignedIn) {
          isMobileAuthPanelOpen.value = true
          authPanelTabIndex.value = 1
        } else {
          await router.push({ name: RoutesNames.ProfileWallet, query: {} })
        }
        isMobileMainMenuOpen.value = false
        isMobileProfilePanelOpen.value = false
        isMobileDepositPanelOpen.value = false
      },
    },
    {
      name: t('mobile-bottom-navbar.search'),
      icon: 'mobile-bottom-navbar-search',
      component: MobileBaseNavButton,
      active: isGlobalSearchFocused.value,
      handler() {
        if (isMobileMainMenuOpen.value && isGlobalSearchFocused.value) {
          return
        }

        if (isGlobalSearchFocused.value) {
          return
        }
        isMobileDepositPanelOpen.value = false
        isGlobalSearchFocused.value = true
        isMobileMainMenuOpen.value = true

        nextTick(() => {
          globalSearchRef.value?.focus()
        })

        // fix the problem on iOS (most likely from the bottom bar in safari)
        setTimeout(() => {
          scrollTo(0, 0)
        }, 100)
      },
    },
  ]
})
</script>
