<template>
  <div ref="rootRef" class="relative">
    <SkeletonBone
      v-if="isAllGamesLoading && !isMobileLayout"
      class="h-[46px] w-full desktop:w-[180px]"
    />
    <AppInput
      v-else
      ref="inputRef"
      v-model="searchString"
      :placeholder="$t('filters.name-search')"
      name="search"
      class="w-full desktop:w-[180px]"
      @focus="handleInputFocus"
    >
      <template #icon>
        <AppIcon
          v-if="searchString.length === 0"
          name="search-16"
          class="cursor-pointer"
          color="r-var(--ne-violet)"
        ></AppIcon>
        <AppIcon
          v-else
          name="close-16"
          class="cursor-pointer"
          color="var(--ne-violet)"
          @click="clearSearchString"
        ></AppIcon>
      </template>
    </AppInput>

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="isGamesPopoverVisible"
        class="absolute right-0 w-[100%] mt-ne-8 z-10 laptop:min-w-[322px]"
      >
        <div class="bg-ne-bg-4 p-ne-16 pr-ne-8 rounded-ne-s">
          <div v-if="isLoading">
            <div
              v-for="it of 5"
              :key="it"
              class="flex rounded-ne-s p-ne-10 border-2 border-ne-bg-5 bg-ne-bg-4 mt-ne-10 first:mt-0"
            >
              <SkeletonBone class="w-[120px] aspect-[4/3]" />
              <div class="ml-ne-16 flex flex-col items-left">
                <SkeletonBone class="w-[100px] h-[40px]" />
                <SkeletonBone class="w-[76px] h-ne-16 mt-ne-4" />
              </div>
            </div>
          </div>
          <div
            v-else-if="!isLoading && gamesList && gamesList.length === 0"
            class="flex items-center justify-center w-full h-full text-center"
          >
            <span class="py-ne-16 text-ne-16 font-bold text-ne-violet-light">
              {{ $t('filters.no-games') }}
            </span>
          </div>
          <template v-else-if="gamesList">
            <div
              class="max-h-[calc(100dvh-250px)] h-full laptop:max-h-[570px] overflow-y-scroll pr-ne-8"
            >
              <component
                :is="isSignedIn ? 'router-link' : 'div'"
                v-for="game of gamesList"
                :key="game.id"
                :to="isSignedIn ? { path: `/game/${game.slug}` } : null"
                class="block mt-ne-10 first:mt-0 cursor-pointer"
                @click="isSignedIn ? null : goToSignUp()"
              >
                <div
                  class="flex rounded-ne-s p-ne-10 border-2 border-ne-bg-5 bg-ne-bg-4 hover:bg-ne-bg-5"
                >
                  <div
                    class="relative bg-ne-bg-3 w-[120px] shrink-0 aspect-[4/3] rounded-lg flex items-center overflow-hidden rounded-ne-s"
                  >
                    <img class="w-full" :alt="game.name" :src="game.imageUrl" />
                  </div>
                  <div class="ml-ne-16 flex flex-col items-left truncate">
                    <div
                      class="text-ne-14 text-ne-white text-left font-bold leading-[140%] min-h-[40px] truncate"
                    >
                      {{ game.name }}
                    </div>
                    <div
                      class="text-ne-12 text-ne-neutral leading-[150%] text-left mt-auto truncate"
                    >
                      {{ game.provider }}
                    </div>
                    <div class="text-ne-link text-left truncate">
                      <router-link
                        v-if="game.hasDemo"
                        :to="{ path: `/game-demo/${game.slug}` }"
                        class="mr-auto truncate"
                        @click.stop
                      >
                        {{ $t('games-list.play-for-fun') }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </component>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import Fuse from 'fuse.js'
import AppInput from '@/components/app/AppInput.vue'
import AppIcon from '@/components/app/AppIcon.vue'
import SkeletonBone from '@/components/SkeletonBone.vue'

import {
  Game,
  getGamesSearchList as getGamesSearchListAction,
} from '@/api/actions/getGamesSearchList'
import { useAuthStore } from '@/stores/auth'
import { useGamesCatalogStore } from '@/stores/gamesCatalog'
import { useSafeInject } from '@/utils/useSafeInject'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import { useMobileLayoutStore } from '@/stores/mobileLayout'

const api = {
  getGamesSearchList: getGamesSearchListAction,
}

export default defineComponent({
  name: 'GamesGlobalSearch',

  components: {
    AppInput,
    AppIcon,
    SkeletonBone,
  },

  setup() {
    const rootRef = ref<HTMLDivElement | null>(null)
    const inputRef = ref<HTMLInputElement | null>(null)

    const isMobileLayout = useSafeInject(IS_MOBILE_LAYOUT_KEY, ref(false))
    const { isGlobalSearchFocused } = toRefs(useMobileLayoutStore())

    const gamesCatalogStore = useGamesCatalogStore()

    const authStore = useAuthStore()
    const isSignedIn = computed(() => {
      return authStore.isSignedIn
    })

    const searchString = ref('')

    const gamesList = ref<Game[] | null>(null)
    const isLoading = ref<boolean>(false)

    const isGamesPopoverVisible = ref(false)

    const focus = () => {
      inputRef.value?.focus()
    }

    const handleInputFocus = () => {
      if (gamesList.value || isLoading.value) {
        isGamesPopoverVisible.value = true
      }
    }
    // handle component blur
    onClickOutside(rootRef, () => {
      isGlobalSearchFocused.value = false
      isGamesPopoverVisible.value = false
    })

    const isAllGamesLoading = ref<boolean>(false)
    const searchErrorMessage = ref<string | null>(null)
    const getGamesSearchList = async () => {
      isAllGamesLoading.value = true

      try {
        const response = await api.getGamesSearchList()

        gamesCatalogStore.allExistedGames = response.data

        searchErrorMessage.value = null
      } catch (e) {
        //
      } finally {
        isAllGamesLoading.value = false
      }
    }

    const getSearchGamesList = (name: string) => {
      if (!gamesCatalogStore.allExistedGames) {
        return
      }

      const options = {
        useExtendedSearch: true,
        includeScore: true,
        keys: ['name'],
      }

      const fuse = new Fuse(gamesCatalogStore.allExistedGames, options)

      const result = fuse.search(`${name}`)

      gamesList.value = result.slice(0, 10).map(({ item }) => item)
    }

    const clearGamesData = () => {
      gamesList.value = null
    }
    const clearSearchString = () => {
      searchString.value = ''
    }

    watch(
      () => searchString.value,
      () => {
        if (!searchString.value) {
          clearGamesData()

          isGamesPopoverVisible.value = false

          return
        }

        isGamesPopoverVisible.value = true

        clearGamesData()
        getSearchGamesList(searchString.value.trim())
      }
    )

    const goToSignUp = () => {
      authStore.goToSignUp()
    }

    const init = () => {
      if (!gamesCatalogStore.allExistedGames) {
        getGamesSearchList()
      }
    }

    init()

    return {
      rootRef,
      isSignedIn,
      isLoading,
      isAllGamesLoading,
      searchString,
      gamesList,
      getSearchGamesList,
      clearSearchString,
      clearGamesData,
      goToSignUp,

      isGamesPopoverVisible,
      handleInputFocus,

      inputRef,
      focus,

      isMobileLayout,
    }
  },
})
</script>
