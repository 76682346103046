import { StorageSerializers, useStorage } from '@vueuse/core'
import { ProfileData } from '@/stores/profileStore'
import { MillisecondsTimestamp } from '@/api/utils'

export const useLocalStorageReactiveFields = () => {
  const accessToken = useStorage<string | null>('accessToken', null)
  const refreshToken = useStorage<string | null>('refreshToken', null)
  const expiresAt = useStorage<MillisecondsTimestamp | null>('expiresAt', null)

  const profileData = useStorage<ProfileData | null>(
    'profileData',
    null,
    undefined,
    {
      serializer: StorageSerializers.object,
    }
  )

  const selectedLocale = useStorage<string>('selectedLocale', 'ru')

  return {
    accessToken,
    refreshToken,
    expiresAt,
    profileData,
    selectedLocale,
  }
}
