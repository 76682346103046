<template>
  <AppSelect
    v-if="currentActiveWallet"
    :key="`${profileData?.id}`"
    name="wallet"
    :border="false"
    :background="true"
    class="h-[40px]"
    :items="currencyWallets"
    :model-value="currentActiveWallet"
    :disabled="isChangeDefaultWalletInProgress"
    @update:model-value="changeDefaultWallet"
  >
    <template #selectButton="{ selectedItem }">
      <div
        v-if="selectedItem"
        class="flex justify-between items-center max-h-[16px] flex-1"
      >
        <div class="flex items-center mr-auto">
          <img
            class="w-[24px] h-[24px] mr-[8px]"
            alt="coin"
            :src="currencySelectImages[selectedItem?.currency]"
          />
          <div class="flex flex-col">
            <div
              class="leading-normal text-ne-white font-semibold whitespace-nowrap"
              :class="recalculateSize(selectedItem?.balance)?.title.class"
            >
              {{ recalculateSize(selectedItem?.balance)?.title.value }}
            </div>
            <div
              v-if="selectedItem?.mainCurrencyRecalculation"
              class="leading-none text-ne-neutral whitespace-nowrap"
              :class="[
                selectedItem?.balance.length >= 10 && isMobileLayout
                  ? 'text-ne-10'
                  : 'text-ne-12',
              ]"
            >
              {{
                recalculateSize(selectedItem?.mainCurrencyRecalculation.balance)
                  .subtitle.value
              }}
              {{ selectedItem?.mainCurrency.code }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #default="{ listItem }">
      <div class="flex items-center mr-auto max-h-[24px]">
        <img
          class="w-[24px] h-[24px] mr-[8px]"
          alt="coin"
          :src="currencySelectImages[listItem?.currency]"
        />
        <div class="flex flex-col items-start">
          <span
            class="text-ne-white font-bold whitespace-nowrap"
            :class="recalculateSize(listItem?.balance)?.title.class"
          >
            {{ recalculateSize(listItem?.balance)?.title.value }}
          </span>
          <span
            v-if="!listItem?.isMainCurrency"
            class="text-ne-neutral whitespace-nowrap"
            :class="recalculateSize(listItem?.balance)?.subtitle.class"
          >
            {{
              recalculateSize(listItem?.mainCurrencyRecalculation.balance)
                ?.subtitle.value
            }}
            {{ listItem?.mainCurrency.code }}
          </span>
        </div>
      </div>
    </template>
    <template #listFooter>
      <div
        class="absolute w-full rounded-b-md bg-inherit p-4 px-6 tablet:px-4 desktop:px-6"
      >
        <AppButton class="" view="accent" full @click="exchangeClickHandler">
          {{ $t('mobile-profile-menu.exchange') }}
        </AppButton>
      </div>
    </template>
  </AppSelect>
</template>

<script setup lang="ts">
import AppSelect from '@/components/app/AppSelect.vue'
import { computed, inject, ref, toRefs, watchEffect } from 'vue'
import { useProfileStore } from '@/stores/profileStore'
import { setDefaultWallet } from '@/api/actions/setDefaultWallet'
import { forgotPassword } from '@/api/actions/forgotPassword'
import { checkGoogle2faOtp } from '@/api/actions/checkGoogle2faOtp'
import { updateProfile } from '@/api/actions/updateProfile'
import BtcCoin24 from '@/assets/svg-icons/wallet-coins/24_btc.svg?url'
import EthCoin24 from '@/assets/svg-icons/wallet-coins/24_eth.svg?url'
import EurCoin24 from '@/assets/svg-icons/wallet-coins/24_eur.svg?url'
import RubCoin24 from '@/assets/svg-icons/wallet-coins/24_rub.svg?url'
import UsdCoin24 from '@/assets/svg-icons/wallet-coins/24_usd.svg?url'
import AppButton from '@/components/app/AppButton.vue'
import { useRouter } from 'vue-router'
import { RoutesNames } from '@/router'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import { useResizeObserver } from '@vueuse/core'

const api = {
  setDefaultWallet,
  forgotPassword,
  checkGoogle2faOtp,
  updateProfile,
}

const currencySelectImages = {
  RUB: RubCoin24,
  BTC: BtcCoin24,
  ETH: EthCoin24,
  EUR: EurCoin24,
  USD: UsdCoin24,
}

const router = useRouter()
const rootWidth = ref(document.body.clientWidth || 0)

const { refreshProfile } = useProfileStore()
const { profileData } = toRefs(useProfileStore())
const isMobileLayout = inject(IS_MOBILE_LAYOUT_KEY, ref(false))

if (isMobileLayout.value) {
  useResizeObserver(document.body, (entries) => {
    const entry = entries[0]
    rootWidth.value = entry.contentRect.width
  })
}

const currencyWallets = computed(() => {
  return (
    profileData.value?.wallets.map((w) => ({
      balance: w.balance,
      value: w.currency,
      isDefault: w.isDefault,
      currency: w.currency,
      isMainCurrency: w.isMainCurrency,
      mainCurrencyRecalculation: w.mainCurrencyRecalculation,
      mainCurrency: profileData.value?.mainCurrency,
    })) || []
  )
})

const currentActiveWallet = ref<typeof currencyWallets.value[number] | null>(
  null
)

watchEffect(() => {
  currentActiveWallet.value =
    currencyWallets.value.find((w) => w.isDefault) || null
})

const isChangeDefaultWalletInProgress = ref(false)

async function changeDefaultWallet(
  wallet: typeof currencyWallets.value[number]
) {
  isChangeDefaultWalletInProgress.value = true

  try {
    await api.setDefaultWallet(wallet.currency)
    await refreshProfile()
  } catch {
    //
  } finally {
    isChangeDefaultWalletInProgress.value = false
  }
}

function recalculateSize(value: string) {
  const length = value.length

  const defaults = {
    title: {
      class: 'text-ne-14',
      value,
    },
    subtitle: {
      class: 'text-ne-12',
      value,
    },
  }

  if (!isMobileLayout.value) {
    return defaults
  }

  if (rootWidth.value < 360) {
    defaults.title.class = 'text-ne-12'
    defaults.title.value = length > 8 ? decimalize(value) : value

    defaults.subtitle.class = 'text-ne-10'
    defaults.subtitle.value = length > 7 ? decimalize(value) : value
  }

  if (rootWidth.value <= 375) {
    defaults.title.class = length > 8 ? 'text-ne-12' : 'text-ne-14'
    defaults.title.value = length > 9 ? decimalize(value) : value

    defaults.subtitle.class = length > 8 ? 'text-ne-10' : 'text-ne-12'
    defaults.subtitle.value = length > 8 ? decimalize(value) : value
  }

  if (rootWidth.value <= 400) {
    defaults.title.class = length > 12 ? 'text-ne-12' : 'text-ne-14'
    defaults.title.value = length > 13 ? decimalize(value) : value

    defaults.subtitle.class = length > 12 ? 'text-ne-10' : 'text-ne-12'
    defaults.subtitle.value = length > 9 ? decimalize(value) : value
  }

  return defaults
}

function exchangeClickHandler() {
  router.push({ name: RoutesNames.ProfileExchanger })
}

function decimalize(value: string) {
  return [...value].slice(0, value.length - 3).join('')
}
</script>
