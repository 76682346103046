import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

export interface StartNewBankDepositData {
  amount: number
  currencyCode: string
}

export interface StartNewBankDepositApiRequest {
  amount: number
  currency: string
  payment_config_id: number
}

export type StartNewBankDepositApiResponse = {
  url: string
  valid_till: string
}

export type StartNewBankDepositResponse = {
  url: string
  validTill: string
}

export const startNewBankDeposit = async (
  depositData: StartNewBankDepositData
): Promise<StartNewBankDepositResponse> => {
  const response = await httpClient.post<
    StartNewBankDepositApiResponse,
    HttpClientResponse<
      ApiCommonSuccessResponse<StartNewBankDepositApiResponse>
    >,
    StartNewBankDepositApiRequest
  >(`/api/payments/deposit`, {
    amount: depositData.amount,
    currency: depositData.currencyCode,
    payment_config_id: 12,
  })

  // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data.data
  ) as StartNewBankDepositResponse

  return convertedToCamelCaseResponse
}
