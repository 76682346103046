import { httpClient, HttpClientResponse } from '@/api/httpClient'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

interface GamesSearchListApiResponse {
  data: {
    id: number
    slug: string
    name: string
    image: string
    image_url: string
    has_demo: number
    provider: string
  }[]
}

export interface Game {
  id: number
  slug: string
  name: string
  image: string
  imageUrl: string
  hasDemo: boolean
  provider: string
}

export interface GamesSearchListResponse {
  data: Game[]
}

export const getGamesSearchList =
  async (): Promise<GamesSearchListResponse> => {
    const response = await httpClient.get<
      GamesSearchListApiResponse,
      HttpClientResponse<GamesSearchListApiResponse>
    >('/api/games/search')

    // TODO validate response data
    const convertedToCamelCaseResponse = toCamelCase(
      response.data
    ) as GamesSearchListResponse

    convertedToCamelCaseResponse.data.forEach(
      (it) => (it.image = `${import.meta.env.VITE_API_URL}/${it.image}`)
    )

    return convertedToCamelCaseResponse
  }
