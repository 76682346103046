import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useProfileStore } from '@/stores/profileStore'

declare module 'vue-router' {
  interface RouteMeta extends Record<string | number | symbol, unknown> {
    title?: string
    layout?: {
      name: string
      noHeader?: boolean
      noFooter?: boolean
      darkFooter?: boolean
      isGame?: boolean
    }
    requiresAuth?: boolean
    requiresNotAuth?: boolean
    query?: Record<string, string>
  }
}

export const RoutesNames = {
  Index: 'Index',
  BannerPreview: 'BannerPreview',
  PromoBonusPreview: 'PromoBonusPreview',
  TermsAndConditions: 'TermsAndConditions',
  AboutUs: 'AboutUs',
  Complaints: 'Complaints',
  Faq: 'Faq',
  Tournament: 'Tournament',
  KolesaOtBossa: 'KolesaOtBossa',
  KolesaOtBossaRules: 'KolesaOtBossaRules',
  Bonuses: 'Bonuses',
  VipClubDescription: 'VipClubDescription',
  SeasonRaceDescription: 'SeasonRaceDescription',
  BonusesTermsAndConditions: 'BonusesTermsAndConditions',
  Support: 'Support',
  Payments: 'Payments',
  ResponsibleGaming: 'ResponsibleGaming',
  PrivacyPolicy: 'PrivacyPolicy',
  ResetPasswordPage: 'ResetPasswordPage',
  Profile: 'Profile',
  ProfileMain: 'ProfileMain',
  ProfileMainGeneral: 'ProfileMainGeneral',
  ProfileMainVerification: 'ProfileMainVerification',
  ProfileMainSecurity: 'ProfileMainSecurity',
  ProfileBonuses: 'ProfileBonuses',
  ProfileBonusesAll: 'ProfileBonusesAll',
  ProfileBonusesActive: 'ProfileBonusesActive',
  ProfileBonusesHistory: 'ProfileBonusesHistory',
  ProfileWalletBalances: 'ProfileWalletBalances',
  ProfileWalletDeposit: 'ProfileWalletDeposit',
  ProfileWalletHistory: 'ProfileWalletHistory',
  ProfileWalletWithdrawal: 'ProfileWalletWithdrawal',
  ProfileVipClub: 'ProfileVipClub',
  ProfileExchanger: 'ProfileExchanger',
  ProfileExchangerExchange: 'ProfileExchangerExchange',
  ProfileExchangerHistory: 'ProfileExchangerHistory',
  ProfileWallet: 'ProfileWallet',
  ProfileGameHistory: 'ProfileGameHistory',
  ProfileResponsibleGame: 'ProfileResponsibleGame',
  ProfileNotifications: 'ProfileNotifications',
  Game: 'Game',
  GameDemo: 'GameDemo',
  ForbiddenCountry: 'ForbiddenCountry',
  Error: 'Error',
}

const LayoutNames = {
  DefaultLayout: 'DefaultLayout',
  MobileLayout: 'MobileLayout',
  BlankLayout: 'BlankLayout',
  OnlyHeaderLayout: 'OnlyHeaderLayout',
  None: 'None',
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: RoutesNames.Index,
    component: () => import('@/pages/MainPage/index.vue'),
    meta: {
      title: 'Main Page',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/banner-preview',
    name: RoutesNames.BannerPreview,
    component: () => import('@/pages/BannerPreviewPage.vue'),
    meta: {
      layout: {
        name: LayoutNames.None,
      },
    },
  },
  {
    path: '/bonus-preview',
    name: RoutesNames.PromoBonusPreview,
    component: () => import('@/pages/PromoBonusPreviewPage.vue'),
    meta: {
      layout: {
        name: LayoutNames.None,
      },
    },
  },
  {
    path: '/reset-password',
    name: RoutesNames.ResetPasswordPage,
    component: () => import('@/pages/ResetPasswordPage.vue'),
    meta: {
      title: 'Reset Password',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/terms',
    name: RoutesNames.TermsAndConditions,
    component: () =>
      import('@/pages/TermsAndConditionsPage/TermsAndConditionsPage.vue'),
    meta: {
      title: 'Terms and Conditions',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/about-us',
    name: RoutesNames.AboutUs,
    component: () => import('@/pages/AboutUsPage.vue'),
    meta: {
      title: 'About Us',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/complaints',
    name: RoutesNames.Complaints,
    component: () => import('@/pages/ComplaintsPage.vue'),
    meta: {
      title: 'Complaints',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/faq',
    name: RoutesNames.Faq,
    component: () => import('@/pages/Faq/FaqPage.vue'),
    meta: {
      title: 'FAQ',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/tournament',
    name: RoutesNames.Tournament,
    component: () => import('@/pages/TournamentPage/TournamentPage.vue'),
    meta: {
      title: 'Tournament',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/kolesa-ot-bossa',
    name: RoutesNames.KolesaOtBossa,
    component: () =>
      import('@/pages/TournamentPage/child-pages/KolesaOtBossaPage.vue'),
    meta: {
      title: 'Tournament',
      layout: {
        name: LayoutNames.DefaultLayout,
      },
    },
  },
  {
    path: '/rules/kolesa-ot-bossa',
    name: RoutesNames.KolesaOtBossaRules,
    component: () =>
      import('@/pages/TournamentPage/child-pages/KolesaOtBossaRulesPage.vue'),
    meta: {
      title: 'Rules',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/bonuses',
    name: RoutesNames.Bonuses,
    component: () => import('@/pages/BonusesPage.vue'),
    meta: {
      title: 'Bonuses',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/vip-club-description',
    name: RoutesNames.VipClubDescription,
    component: () =>
      import('@/pages/VipClubDescriptionPage/VipClubDescriptionPage.vue'),
    meta: {
      title: 'VIP club',
      layout: { name: LayoutNames.DefaultLayout, noFooter: false },
    },
  },
  {
    path: '/season-race-description',
    name: RoutesNames.SeasonRaceDescription,
    component: () =>
      import('@/pages/SeasonRaceDescriptionPage/SeasonRaceDescriptionPage.vue'),
    meta: {
      title: 'Season race',
      layout: { name: LayoutNames.DefaultLayout, noFooter: false },
    },
  },
  {
    path: '/support',
    name: RoutesNames.Support,
    component: () => import('@/pages/SupportPage.vue'),
    meta: {
      title: 'Support',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/payments',
    name: RoutesNames.Payments,
    component: () => import('@/pages/PaymentsPage.vue'),
    meta: {
      title: 'Payments',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/bonuses-terms',
    name: RoutesNames.BonusesTermsAndConditions,
    component: () =>
      import(
        '@/pages/BonusesTermsAndConditions/BonusesTermsAndConditionsPage.vue'
      ),
    meta: {
      title: 'Bonuses Terms and Conditions',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/responsible-game',
    name: RoutesNames.ResponsibleGaming,
    component: () =>
      import('@/pages/ResponsibleGamingPage/ResponsibleGamingPage.vue'),
    meta: {
      title: 'Responsible Gaming',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/privacy',
    name: RoutesNames.PrivacyPolicy,
    component: () => import('@/pages/PrivacyPolicyPage/PrivacyPolicyPage.vue'),
    meta: {
      title: 'Privacy Policy',
      layout: { name: LayoutNames.DefaultLayout },
    },
  },
  {
    path: '/profile',
    name: RoutesNames.Profile,
    component: () => import('@/pages/ProfilePage/index.vue'),
    redirect: { name: RoutesNames.ProfileMain },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'main',
        name: RoutesNames.ProfileMain,
        component: () =>
          import('@/pages/ProfilePage/child-pages/MainPage/index.vue'),
        redirect: { name: RoutesNames.ProfileMainGeneral },
        meta: {
          layout: { name: LayoutNames.DefaultLayout, darkFooter: true },
        },
        children: [
          {
            path: 'general',
            name: RoutesNames.ProfileMainGeneral,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/MainPage/child-pages/GeneralPage.vue'
              ),
            meta: {
              title: 'Profile',
            },
          },
          {
            path: 'verification',
            name: RoutesNames.ProfileMainVerification,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/MainPage/child-pages/VerificationPage/index.vue'
              ),
            meta: {
              title: 'Verification',
            },
          },
          {
            path: 'security',
            name: RoutesNames.ProfileMainSecurity,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/MainPage/child-pages/SecurityPage/SecurityPage.vue'
              ),
            meta: {
              title: 'Security',
            },
          },
        ],
      },
      {
        path: 'bonuses',
        name: RoutesNames.ProfileBonuses,
        component: () =>
          import(
            '@/pages/ProfilePage/child-pages/BonusesProfilePage/index.vue'
          ),
        redirect: { name: RoutesNames.ProfileBonusesAll },
        meta: {
          title: 'Bonuses',
          layout: { name: LayoutNames.DefaultLayout, darkFooter: true },
        },
        children: [
          {
            path: 'all',
            name: RoutesNames.ProfileBonusesAll,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/BonusesProfilePage/child-pages/AllBonusesPage/AllBonusesPage.vue'
              ),
            meta: {
              title: 'All bonuses',
            },
          },
          {
            path: 'active',
            name: RoutesNames.ProfileBonusesActive,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/BonusesProfilePage/child-pages/ActiveBonusesPage/ActiveBonusesPage.vue'
              ),
            meta: {
              title: 'Active bonuses',
            },
          },
          {
            path: 'history',
            name: RoutesNames.ProfileBonusesHistory,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/BonusesProfilePage/child-pages/HistoryBonusesPage.vue'
              ),
            meta: {
              title: 'Bonuses history',
            },
          },
        ],
      },
      {
        path: 'vip-club',
        name: RoutesNames.ProfileVipClub,
        component: () =>
          import('@/pages/ProfilePage/child-pages/VipClubPage/VipClubPage.vue'),
        meta: {
          title: 'VIP Program',
          layout: { name: LayoutNames.DefaultLayout, darkFooter: true },
        },
      },
      {
        path: 'exchanger',
        name: RoutesNames.ProfileExchanger,
        redirect: { name: RoutesNames.ProfileExchangerExchange },
        component: () =>
          import(
            '@/pages/ProfilePage/child-pages/ExchangerPage/ExchangerPage.vue'
          ),
        meta: {
          title: 'Exchanger',
          layout: { name: LayoutNames.DefaultLayout, darkFooter: true },
        },
        children: [
          {
            path: 'exchange',
            name: RoutesNames.ProfileExchangerExchange,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/ExchangerPage/child-pages/ExchangeExchangerPage.vue'
              ),
            meta: {
              title: 'Exchange',
            },
          },
          {
            path: 'history',
            name: RoutesNames.ProfileExchangerHistory,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/ExchangerPage/child-pages/HistoryExchangerPage.vue'
              ),
            meta: {
              title: 'Exchanges history',
            },
          },
        ],
      },
      {
        path: 'wallet',
        name: RoutesNames.ProfileWallet,
        component: () =>
          import('@/pages/ProfilePage/child-pages/WalletPage/index.vue'),
        redirect: { name: RoutesNames.ProfileWalletBalances },
        meta: {
          layout: { name: LayoutNames.DefaultLayout, darkFooter: true },
        },
        children: [
          {
            path: 'balances',
            name: RoutesNames.ProfileWalletBalances,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/WalletPage/child-pages/BalancesWalletPage/BalancesWalletPage.vue'
              ),
            meta: {
              title: 'Balances',
            },
          },
          {
            path: 'deposit',
            name: RoutesNames.ProfileWalletDeposit,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/WalletPage/child-pages/DepositWalletPage.vue'
              ),
            meta: {
              title: 'Deposit',
            },
          },
          {
            path: 'withdrawal',
            name: RoutesNames.ProfileWalletWithdrawal,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/WalletPage/child-pages/WithdrawalWalletPage.vue'
              ),
            meta: {
              title: 'Withdrawal',
            },
          },
          {
            path: 'history',
            name: RoutesNames.ProfileWalletHistory,
            component: () =>
              import(
                '@/pages/ProfilePage/child-pages/WalletPage/child-pages/HistoryWalletPage.vue'
              ),
            meta: {
              title: 'Wallet History',
            },
          },
        ],
      },
      {
        path: 'game-history',
        name: RoutesNames.ProfileGameHistory,
        component: () =>
          import('@/pages/ProfilePage/child-pages/GameHistoryPage.vue'),
        meta: {
          title: 'Games History',
          layout: { name: LayoutNames.DefaultLayout, darkFooter: true },
        },
      },
      {
        path: 'responsible-game',
        name: RoutesNames.ProfileResponsibleGame,
        component: () =>
          import(
            '@/pages/ProfilePage/child-pages/ResponsibleGamePage/ResponsibleGamePage.vue'
          ),
        meta: {
          title: 'Responsible Game',
          layout: { name: LayoutNames.DefaultLayout, darkFooter: true },
        },
      },
      {
        path: 'notifications',
        name: RoutesNames.ProfileNotifications,
        component: () =>
          import(
            '@/pages/ProfilePage/child-pages/NotificationsPage/NotificationsPage.vue'
          ),
        meta: {
          title: 'Notifications',
          layout: { name: LayoutNames.DefaultLayout, darkFooter: true },
        },
      },
    ],
  },
  {
    path: '/game/:gameSlug',
    name: RoutesNames.Game,
    component: () => import('@/pages/GamePage.vue'),
    meta: {
      title: 'Game',
      layout: {
        name: LayoutNames.DefaultLayout,
        noFooter: true,
        isGame: true,
      },
      requiresAuth: true,
    },
  },
  {
    path: '/game-demo/:gameSlug',
    name: RoutesNames.GameDemo,
    component: () => import('@/pages/GamePage.vue'),
    meta: {
      title: 'Game Demo',
      layout: {
        name: LayoutNames.DefaultLayout,
        noFooter: true,
        isGame: true,
      },
      isDemo: true,
    },
  },
  {
    path: '/forbidden-country',
    name: RoutesNames.ForbiddenCountry,
    component: () => import('@/pages/ForbiddenCountry.vue'),
    meta: {
      layout: {
        name: LayoutNames.None,
      },
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    redirect: { name: RoutesNames.Index },
    // component: PageNotFound,
    // meta: {
    //   requiresAuth: false
    // },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  const profileStore = useProfileStore()

  if (
    to.meta.requiresAuth &&
    !authStore.isSignedIn &&
    to.name !== RoutesNames.Index
  ) {
    return {
      name: RoutesNames.Index,
    }
  } else if (authStore.isSignedIn && !authStore.isTokenValid()) {
    try {
      await authStore.refreshAuth()
    } catch {
      await authStore.signOut()
    }
  } else if (authStore.isSignedIn) {
    profileStore.refreshProfile()
  }

  if (from.name !== null) {
    ;(to.params as Record<string, unknown>).previousRoute = from.name
  }
})

export default router
