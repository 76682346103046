import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppStateStore = defineStore('appState', () => {
  const isQuickDepositDialogOpen = ref(false)

  function toggleQuickDepositDialog() {
    isQuickDepositDialogOpen.value = !isQuickDepositDialogOpen.value
  }

  return {
    isQuickDepositDialogOpen,
    toggleQuickDepositDialog,
  }
})
