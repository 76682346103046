<template>
  <Combobox
    v-slot="{ open }"
    v-bind="$attrs"
    :key="comboboxKey"
    :class="{ 'opacity-40 pointer-events-none': disabled }"
    :disabled="disabled"
    :model-value="modelValue || undefined"
    as="div"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <ComboboxLabel
      v-if="label"
      class="block text-ne-14 mb-ne-8"
      :class="{
        'text-ne-black': light,
        'text-ne-white': !light,
      }"
    >
      <span v-if="required" class="text-ne-error">*</span>
      {{ label }}
    </ComboboxLabel>
    <div class="relative">
      <slot name="inputImg"></slot>
      <ComboboxInput
        class="relative z-10 w-full flex flex-nowrap items-center text-ne-14 pr-ne-16 pl-ne-40 py-[12px] text-left disabled:cursor-auto border-2 border-ne-violet placeholder-ne-neutral hover:border-ne-violet-light focus:border-ne-violet-light disabled:hover:border-ne-violet rounded-ne-s"
        :class="{
          'border-0': !border,
          'bg-ne-bg-4': background,
          'bg-transparent': !background,
          'text-ne-black': light,
          'text-ne-white': !light,
          'border-ne-error': error,
          'hover:border-ne-error': error,
          'focus:border-ne-error': error,
        }"
        :placeholder="placeholder"
        :disabled="disabled"
        :display-value="getDisplayValue"
        @change="query = $event.target.value"
      >
      </ComboboxInput>

      <ComboboxButton
        class="absolute inset-y-0 pr-ne-16 pl-ne-4 right-0 flex items-center z-50 cursor-pointer"
      >
        <AppIcon
          :class="[open ? 'transform rotate-180' : '']"
          name="open-down-16"
          color="var(--ne-violet-light)"
        />
      </ComboboxButton>

      <TransitionRoot @after-leave="clearQuery">
        <ComboboxOptions
          class="absolute z-10 mt-ne-8 w-full bg-ne-bg-4 text-ne-14 max-h-56 rounded-md py-ne-8 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
          :class="{
            'bg-ne-white': light,
            'bg-opacity-100': light,
            'shadow-ne-s': light,
          }"
        >
          <li
            v-if="filteredItems.length === 0 && query !== ''"
            class="select-none relative block px-ne-16 py-[12px] pr-[36px]"
          >
            <div class="text-center">Nothing found</div>
          </li>

          <ComboboxOption
            v-for="(item, index) in filteredItems"
            :key="index"
            v-slot="{ active, selected }"
            as="template"
            :value="item"
          >
            <li
              class="cursor-pointer select-none relative block px-ne-16 py-[12px] pr-[36px]"
              :class="{
                'bg-ne-violet bg-opacity-50': selected,
                'bg-ne-violet bg-opacity-30': active && !selected,
                'bg-ne-violet bg-opacity-20': light && selected,
                'bg-ne-bg-4 bg-opacity-10': light && active && !selected,
              }"
            >
              <div class="flex items-center">
                <slot name="optionImg" :item="item"></slot>
                <span class="block truncate">
                  {{ item.name }}
                </span>
              </div>

              <span
                v-if="selected"
                class="absolute inset-y-0 right-ne-16 flex items-center"
              >
                <AppIcon name="ok-16" color="r-var(--ne-success)" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
    <p
      v-if="error && errorMessage"
      :id="`${name}-error`"
      class="mt-ne-10 text-ne-12 text-ne-error"
      aria-live="assertive"
    >
      {{ errorMessage }}
    </p>
    <p v-else-if="message" class="mt-ne-10 text-ne-12 text-gray-500">
      {{ message }}
    </p>
  </Combobox>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue'
import {
  Combobox,
  ComboboxLabel,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'
import AppIcon from './AppIcon.vue'

type ListItem = Record<'name' | 'value' | string, string | any>

export default defineComponent({
  name: 'AppCombobox',

  components: {
    Combobox,
    ComboboxLabel,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,

    AppIcon,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Object as PropType<ListItem[]>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<ListItem | null>,
      default: null,
    },
    light: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    background: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const query = ref('')

    const filteredItems = computed(() =>
      query.value === ''
        ? props.items
        : props.items.filter((item) => {
            return item.name.toLowerCase().includes(query.value.toLowerCase())
          })
    )

    const comboboxKey = ref(0)
    const clearQuery = () => {
      query.value = ''
      // force component rerender
      comboboxKey.value += 1
    }

    const getDisplayValue = (val: any) => {
      return val?.name as string
    }

    return {
      query,
      filteredItems,
      clearQuery,
      getDisplayValue,
      comboboxKey,
    }
  },
})
</script>
