<script setup lang="ts">
import AppSelect from '@/components/app/AppSelect.vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLanguageSelector } from '@/composables/useLanguageSelector'

const i18n = useI18n()
const { handleLocaleChange } = useLanguageSelector()

const availableLocales = computed(() =>
  i18n.availableLocales.map((locale) => ({
    name: locale.toUpperCase(),
    value: locale,
  }))
)
const currentLocale = computed(
  () =>
    availableLocales.value &&
    availableLocales.value.find(
      (locale) => locale.name.toLowerCase() === i18n.locale.value.toLowerCase()
    )
)

function onChange({ name }: { name: string }) {
  handleLocaleChange(name)
}
</script>

<template>
  <AppSelect
    v-if="availableLocales && currentLocale"
    :key="`${currentLocale}`"
    class="block w-[76px] flex-shrink-0 tablet:mr-ne-8"
    name="locale"
    :border="false"
    :items="availableLocales"
    :model-value="currentLocale"
    @update:model-value="onChange"
  />
</template>
