import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

export interface SignUpData {
  email: string
  password: string
  countryId: number
  stag?: string
  locale: string
  deviceId: string
  token: string | undefined
  promocode?: string
  phone?: string
}

export interface SignUpApiRequest {
  email: string
  password: string
  country_id: number
  stag?: string
  locale: string
  device_id: string
  recaptcha_token: string | undefined
  promo_code?: string
  phone?: string
}

interface SignUpApiResponse {
  id: number
  email: string
}

interface SignUpResponse {
  id: number
  email: string
}

export const signUp = async (
  signUpData: SignUpData
): Promise<SignUpResponse> => {
  const params: SignUpApiRequest = {
    email: signUpData.email,
    password: signUpData.password,
    country_id: signUpData.countryId,
    locale: signUpData.locale,
    device_id: signUpData.deviceId,
    recaptcha_token: signUpData.token,
    promo_code: signUpData.promocode,
    phone: signUpData.phone,
  }

  if (signUpData.stag) {
    params.stag = signUpData.stag
  }

  const response = await httpClient.post<
    SignUpApiResponse,
    HttpClientResponse<ApiCommonSuccessResponse<SignUpApiResponse>>,
    SignUpApiRequest
  >('/api/auth/signup-v2', params)

  return response.data.data
}
