import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import Maska from 'maska'
import '@yzfe/svgicon/lib/svgicon.css'
import { useLocalStorageReactiveFields } from '@/composables/useLocalStorageReactiveFields'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'
import './styles/index.css'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import messages from '@intlify/vite-plugin-vue-i18n/messages'

const { profileData, selectedLocale } = useLocalStorageReactiveFields()

const i18n = createI18n({
  locale:
    profileData.value?.locale ||
    selectedLocale.value ||
    import.meta.env.VITE_I18N_LOCALE ||
    'en',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  messages,
  globalInjection: true,
})

const app = createApp(App)

Sentry.init({
  app,
  environment: import.meta.env.MODE,
  // eslint-disable-next-line
  release: `${import.meta.env.VITE_APP_NAME}@${
    import.meta.env.VITE_APP_VERSION
  }`,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
    new Sentry.BrowserProfilingIntegration(),
  ],
  debug: !import.meta.env.PROD,
  tracesSampleRate: import.meta.env.PROD ? 0.2 : 1,
  replaysSessionSampleRate: import.meta.env.PROD ? 0.2 : 1,
  replaysOnErrorSampleRate: 1,
  attachStacktrace: true,
  tracingOptions: {
    trackComponents: true,
    hooks: ['mount', 'update'],
    timeout: 1000,
  },
})

app.use(VueSvgIconPlugin, {
  tagName: 'vue-svg-icon',
})
app.use(i18n)
app.use(Maska)
app.use(createPinia())
app.use(router)
app.component(VueQrcode.name, VueQrcode)

app.mount('#app')
