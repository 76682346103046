import { httpClient, HttpClientResponse } from '@/api/httpClient'

import { ApiCommonSuccessResponse } from '@/api/utils'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toCamelCase } from '@/utils/casesConverter.js'

export interface StartNewPiastrixDepositData {
  amount: number
  currencyCode: string
}

export interface StartNewPiastrixDepositApiRequest {
  amount: number
  currency: string
  payment_config_id: number
}

export type StartNewPiastrixDepositApiResponse = {
  url: string
  valid_till: string
}

export type StartNewPiastrixDepositResponse = {
  url: string
  validTill: string
}

export const startNewPiastrixDeposit = async (
  depositData: StartNewPiastrixDepositData
): Promise<StartNewPiastrixDepositResponse> => {
  const response = await httpClient.post<
    StartNewPiastrixDepositApiResponse,
    HttpClientResponse<
      ApiCommonSuccessResponse<StartNewPiastrixDepositApiResponse>
    >,
    StartNewPiastrixDepositApiRequest
  >(`/api/payments/deposit`, {
    amount: depositData.amount,
    currency: depositData.currencyCode,
    payment_config_id: 13,
  })

  // TODO validate response data
  const convertedToCamelCaseResponse = toCamelCase(
    response.data.data
  ) as StartNewPiastrixDepositResponse

  return convertedToCamelCaseResponse
}
