<template>
  <component :is="layout" v-bind="layoutOptions">
    <slot />
    <QuickDepositDialog />
  </component>
</template>

<script setup lang="ts">
import BlankLayout from '@/layouts/BlankLayout.vue'
import NoneLayout from '@/layouts/NoneLayout.vue'
import MobileLayout from '@/layouts/DefaultLayout/MobileLayout/MobileLayout.vue'

import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useMediaQueryFlags } from '@/composables/useMediaQueryFlags'
import DesktopLayout from '@/layouts/DefaultLayout/DesktopLayout/DesktopLayout.vue'
import QuickDepositDialog from '@/components/QuickDepositDialog.vue'

const route = useRoute()

const { isMobile } = useMediaQueryFlags()

const layouts = computed<{ [key: string]: any }>(() => ({
  DefaultLayout: isMobile.value ? MobileLayout : DesktopLayout,
  BlankLayout,
  None: NoneLayout,
}))
const layoutMeta = computed(() => {
  return route.meta?.layout as
    | { name: keyof typeof layouts; noFooter: boolean }
    | undefined
})
const layout = computed(() => {
  const name = layoutMeta.value?.name || 'BlankLayout'
  return layouts.value[name as string]
})
const layoutOptions = computed(() => {
  const { name, ...options } = layoutMeta.value || {}
  return options
})
</script>
