<template>
  <AppForm
    :error-message="errorMessage"
    :loading="loading"
    submit-text="Confirm"
    @submit="handleSubmit"
  >
    <AppInput
      v-model="fieldsData.code"
      :error="validationResult.code.$invalid"
      :error-message="validationResult.code.$messages[0]"
      :disabled="loading || disabled"
      :label="$t('dialogs.activateTwoFactorAuth.code')"
      name="code"
      :light="themeLight"
    />

    <template #footer>
      <div class="flex w-full justify-center">
        <AppButton
          v-if="!hideCancel"
          class="mr-ne-24"
          :disabled="loading || disabled"
          view="secondary"
          @click.stop.prevent="$emit('cancel')"
        >
          {{ $t('appDialog.footer.defaultCancel') }}
        </AppButton>
        <AppButton
          :disabled="loading || disabled"
          :loading="loading"
          :view="accentSubmit ? 'accent' : 'primary'"
          :full="accentSubmit"
          >{{ submitText || $t('appDialog.footer.defaultAccept') }}</AppButton
        >
      </div>
    </template>
  </AppForm>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import useValidate from 'vue-tiny-validate'
import { z } from 'zod'

import AppForm from '@/components/app/AppForm.vue'
import AppInput from '@/components/app/AppInput.vue'
import AppButton from '@/components/app/AppButton.vue'

interface FieldsData {
  code: string
}

export interface EmittedTwoFactorCodeData {
  code: string
}

export default defineComponent({
  name: 'TwoFactorCodeForm',

  components: { AppForm, AppInput, AppButton },

  props: {
    errorMessage: {
      type: String as PropType<string | null>,
      default: null,
    },
    submitText: {
      type: String as PropType<string | null>,
      default: null,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    themeLight: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    accentSubmit: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submit', 'cancel'],

  setup(props, context) {
    const { t } = useI18n({ useScope: 'global' })

    const fieldsData = reactive<FieldsData>({
      code: '',
    })

    const fieldsRules = computed(() => {
      const required = {
        test: (value: string | object | boolean): boolean =>
          z.string().nonempty().safeParse(value).success ||
          (typeof value === 'object' && value !== null) ||
          value === true,
        message: t('validation-errors.required'),
        name: 'required',
      }

      return {
        code: [required],
      }
    })

    const { result: validationResult } = useValidate(fieldsData, fieldsRules)

    const handleSubmit = () => {
      validationResult.value.$test()

      if (!validationResult.value.$invalid) {
        const submitData: EmittedTwoFactorCodeData = {
          code: fieldsData.code,
        }

        context.emit('submit', submitData)
      }
    }

    return {
      fieldsData,

      handleSubmit,
      validationResult,
    }
  },
})
</script>
