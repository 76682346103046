<template>
  <div class="flex flex-col">
    <MobileNavigationElement
      v-for="element of navigation"
      :key="element.name"
      :name="element.name"
      :row-class="element.rowClass"
      :label="element.label"
      @click="element.handler && element.handler()"
    />
  </div>
</template>

<script setup lang="ts">
import MobileNavigationElement from '@/components/mobile/MobileNavigationElement.vue'
import { useRoute, useRouter } from 'vue-router'
import { RoutesNames } from '@/router'
import { NavigationElement } from '@/layouts/DefaultLayout/MobileLayout/types'
import { computed, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMobileLayoutStore } from '@/stores/mobileLayout'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { isMobileMainMenuOpen } = toRefs(useMobileLayoutStore())

watch([route], () => {
  isMobileMainMenuOpen.value = false
})

const navigation = computed<NavigationElement[]>(() => [
  {
    icon: 'mobile-navigation-all-games',
    name: t('mobile-main-menu.all-games'),
    async handler() {
      await router.push({ name: RoutesNames.Index })
      requestAnimationFrame(() => {
        const GAMES_CATALOG = document.querySelector('#games-catalog')
        GAMES_CATALOG?.scrollIntoView()
      })
    },
  },
  {
    icon: 'mobile-navigation-promotion',
    name: t('mobile-main-menu.promotions'),
    handler() {
      router.push({ name: RoutesNames.Bonuses })
    },
  },
  {
    icon: 'mobile-navigation-vip-club',
    name: t('mobile-main-menu.vip-club'),
    handler() {
      router.push({ name: RoutesNames.VipClubDescription })
    },
  },
  {
    icon: 'car-16',
    name: t('mobile-main-menu.tournament'),
    rowClass: 'text-ne-yellow',
    label: 'Exclusive',
    handler() {
      router.push({ name: RoutesNames.KolesaOtBossa })
    },
  },
  {
    icon: 'mobile-navigation-payments',
    name: t('mobile-main-menu.payments'),
    handler() {
      router.push({ name: RoutesNames.Payments })
    },
  },
  {
    icon: 'mobile-navigation-document',
    name: t('mobile-main-menu.faq'),
    handler() {
      router.push({ name: RoutesNames.Faq })
    },
  },
  {
    icon: 'mobile-navigation-document',
    name: t('mobile-main-menu.terms'),
    handler() {
      router.push({ name: RoutesNames.TermsAndConditions })
    },
  },
  {
    icon: 'mobile-navigation-document',
    name: t('mobile-main-menu.about-us'),
    handler() {
      router.push({ name: RoutesNames.AboutUs })
    },
  },
  {
    icon: 'mobile-navigation-document',
    name: t('mobile-main-menu.privacy-policy'),
    handler() {
      router.push({ name: RoutesNames.PrivacyPolicy })
    },
  },
])
</script>
