<template>
  <div class="relative mobile-layout bg-ne-bg-1 touch-none">
    <MobileAppHeader />
    <div
      ref="contentContainerRef"
      class="relative bg-ne-bg-2 overflow-y-auto overflow-x-hidden"
    >
      <MobileMainMenu />
      <slot />
      <AppFooter />
    </div>
    <MobileBottomNavBar />
  </div>
</template>

<script lang="ts" setup>
import MobileBottomNavBar from '@/layouts/DefaultLayout/MobileLayout/MobileBottomNavBar/MobileBottomNavBar.vue'
import MobileMainMenu from '@/layouts/DefaultLayout/MobileLayout/MobileMainMenu/MobileMainMenu.vue'
import MobileAppHeader from '@/layouts/DefaultLayout/MobileLayout/MobileAppHeader/MobileAppHeader.vue'
import AppFooter from '@/components/AppFooter/index.vue'
import { provide, ref, watch } from 'vue'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import { MOBILE_CONTENT_CONTAINER_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/MOBILE_CONTENT_CONTAINER_KEY'
import { useRoute } from 'vue-router'

const isMobileLayout = ref(true)
const contentContainerRef = ref<HTMLElement | null>(null)
const route = useRoute()

provide(IS_MOBILE_LAYOUT_KEY, isMobileLayout)
provide(MOBILE_CONTENT_CONTAINER_KEY, contentContainerRef)

watch(
  () => route.path,
  () => contentContainerRef.value?.scrollTo(0, 0)
)
</script>

<style scoped>
.mobile-layout {
  height: 100dvh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
