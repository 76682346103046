<template>
  <div
    class="flex flex-col"
    :class="[isQuickDepositDialogOpen ? '' : 'desktop-s:flex-row']"
  >
    <div
      v-if="isDepositPaymentMethodsLoading && !filteredDepositPaymentMethods"
      class="flex w-full h-full items-center justify-center"
    >
      <AppSpinner theme="violet" class="w-full" />
    </div>
    <template
      v-if="
        currentActivePaymentMethod &&
        filteredDepositPaymentMethods &&
        currentActiveWallet
      "
    >
      <div v-if="isQuickDepositDialogOpen" class="flex mb-ne-16">
        <span class="text-ne-h3 font-bold mr-[10px]">{{
          $t('profile.wallet.deposit')
        }}</span>
        <div class="flex items-center">
          <span class="mr-[8px] text-ne-neutral text-ne-12">{{
            $t('profile.wallet.on')
          }}</span>
          <img
            class="w-[61px] h-[24px] mr-ne-8"
            alt="coin"
            :src="paymentMethodsMockIcons[currentActiveWallet.currency]"
          />
          <span class="mr-[8px] text-ne-neutral text-ne-12">{{
            $t('profile.wallet.by')
          }}</span>
          <img
            class="min-w-[61px] h-[24px]"
            alt="coin"
            :src="
              paymentMethodsMockIcons[currentActivePaymentMethod.value.code]
            "
          />
        </div>
      </div>
      <WalletAndCurrencySelector
        v-model:paymentMethod="currentActivePaymentMethod"
        :wallet="currentActiveWallet"
        :is-payment-methods-loading="isDepositPaymentMethodsLoading"
        :payment-methods="filteredDepositPaymentMethods"
        :wallets="currencyWallets"
        @update:wallet="onUpdateWallet"
      />
      <div
        class="w-full desktop-s:mt-0"
        :class="[isQuickDepositDialogOpen ? 'mt-ne-8' : 'mt-ne-32']"
      >
        <div v-if="!isQuickDepositDialogOpen" class="flex mb-ne-16">
          <span class="text-ne-h3 font-bold mr-[10px]">{{
            $t('profile.wallet.deposit')
          }}</span>
          <div class="flex items-center">
            <span class="mr-[8px] text-ne-neutral text-ne-12">{{
              $t('profile.wallet.on')
            }}</span>
            <img
              class="w-[61px] h-[24px] mr-ne-8"
              alt="coin"
              :src="paymentMethodsMockIcons[currentActiveWallet.currency]"
            />
            <span class="mr-[8px] text-ne-neutral text-ne-12">{{
              $t('profile.wallet.by')
            }}</span>
            <img
              class="min-w-[61px] h-[24px]"
              alt="coin"
              :src="
                paymentMethodsMockIcons[currentActivePaymentMethod.value.code]
              "
            />
          </div>
        </div>
        <template
          v-if="
            NonCryptoNetworks.includes(currentActivePaymentMethod.value.code)
          "
        >
          <div class="mt-ne-16">
            <AppForm :loading="isNonCryptoLoading" hide-footer>
              <AppInput
                v-model="nonCryptoAmountToDeposit"
                :disabled="isNonCryptoLoading"
                :error="!!nonCryptoErrorMessage"
                :error-message="nonCryptoErrorMessage"
                name="amount"
                type="number"
                step="any"
                :label="`${$t('profile.wallet.amount')} ${
                  currentActiveWallet?.currency || ''
                }`"
              />
              <DepositBonuses
                :bonuses-list="bonusesListTransformed"
                :is-bonuses-loading="isBonusesLoading"
                :is-use-bonuses-enabled="isUseBonusesEnabled"
                :currency="currentActiveWallet.currency"
                class="mt-ne-24"
                @need-update="refreshBonuses"
              />
              <template #footer>
                <AppButton
                  v-if="currentActiveWallet"
                  view="primary"
                  :loading="isNonCryptoLoading"
                  :text="
                    $t('profile.wallet.deposit') +
                    ` ${currentActiveWallet.currency}`
                  "
                />
              </template>
            </AppForm>
          </div>
        </template>

        <template v-else>
          <div class="mt-ne-16">
            <div
              v-if="
                cryptoAddress || isCryptoLoading || cryptoAddressLoadingError
              "
              class="mt-ne-16"
            >
              <div
                class="mt-ne-16 bg-ne-bg-3 rounded-ne-s tablet:min-h-[215px]"
              >
                <div class="px-ne-16 py-[12px] bg-ne-bg-4 rounded-ne-s">
                  <div
                    v-if="isCryptoLoading"
                    class="flex items-center justify-center"
                  >
                    <AppSpinner theme="violet" class="mr-ne-8" />
                    <span>{{ $t('profile.wallet.wait') }}</span>
                  </div>
                  <div
                    v-else-if="cryptoAddressLoadingError"
                    class="text-ne-16 text-ne-error text-center break-all"
                  >
                    {{ cryptoAddressLoadingError }}
                  </div>
                  <div v-else class="flex justify-between items-center w-full">
                    <span
                      class="text-ne-14 desktop-s:text-ne-12 text-ne-white font-bold break-all mr-[20px]"
                      >{{ cryptoAddress }}</span
                    >
                    <AppButton
                      icon
                      size="small"
                      :disabled="isCryptoLoading || !!cryptoAddressLoadingError"
                      class="w-[32px] h-[32px]"
                      view="primary"
                      @click="copyCryptoAddress()"
                    >
                      <img
                        class="mx-auto w-[16px] h-[16px]"
                        alt="copy"
                        :src="CopyIcon"
                      />
                    </AppButton>
                  </div>
                </div>
                <div
                  v-if="cryptoAddress"
                  class="flex flex-col tablet:flex-row p-[20px] justify-between"
                >
                  <div class="flex flex-col mb-ne-24 tablet:mb-0">
                    <div class="flex items-center flex-shrink-0">
                      <div class="flex desktop-s:flex-col mb-ne-8">
                        <div class="flex">
                          <AppIcon
                            class="w-[16px] h-[16px] fill-ne-yellow mr-ne-10"
                            alt="copy"
                            name="master-yellow"
                          />
                          <span class="text-ne-yellow text-ne-14 mr-[8px]"
                            >{{ $t('profile.wallet.network') }}:</span
                          >
                        </div>
                        <span class="block w-full text-ne-yellow text-ne-14">{{
                          NetworkNames[currentActivePaymentMethod.value.code]
                        }}</span>
                      </div>
                    </div>
                    <span class="text-ne-12">{{
                      $t('profile.wallet.network-text')
                    }}</span>
                  </div>
                  <div
                    class="w-[120px] h-[120px] flex-shrink-0 tablet:ml-[80px] desktop-s:ml-[12px]"
                  >
                    <vue-qrcode
                      :value="cryptoAddress"
                      :options="{ width: 120 }"
                    ></vue-qrcode>
                  </div>
                </div>
              </div>

              <DepositBonuses
                :bonuses-list="bonusesListTransformed"
                :is-bonuses-loading="isBonusesLoading"
                :is-use-bonuses-enabled="isUseBonusesEnabled"
                :currency="currentActiveWallet.currency"
                class="mt-ne-24"
                @need-update="refreshBonuses"
              />

              <AppButton
                class="mt-ne-32"
                view="primary"
                :disabled="isCryptoLoading || !!cryptoAddressLoadingError"
                :text="copyButtonText"
                @click="copyCryptoAddress"
              />
              <div
                v-if="isCryptoAddressCopied"
                class="mt-ne-8 text-ne-12 text-ne-neutral"
              >
                {{ $t('profile.wallet.address-copied') }}
              </div>
            </div>
          </div>
        </template>

        <AppForm
          class="mt-ne-40 h-fit"
          :loading="isPromoLoading"
          :error-message="promoErrorMessage"
          :success-message="promoSuccessMessage"
          @submit="handlePromoFormSubmit"
        >
          <AppButton
            v-if="currentActiveWallet && isMobileLayout && !cryptoAddress"
            class="w-full mb-ne-24"
            type="button"
            view="primary"
            :loading="isNonCryptoLoading"
            :text="
              $t('profile.wallet.deposit') +
              ` (${currentActiveWallet.currency})`
            "
            @click="handleNonCryptoFormSubmit"
          />
          <div
            class="flex tablet:items-end gap-ne-24 tablet:gap-0 flex-col tablet:flex-row"
          >
            <AppInput
              v-model="promo"
              class="w-full mr-ne-16"
              :disabled="isPromoLoading"
              :placeholder="$t('profile.promo.placeholder')"
              name="promo"
              type="text"
              :label="$t('profile.promo.title')"
              @focus="cleanPromoMessages"
            />
            <AppButton
              view="primary"
              class="h-[46px]"
              :loading="isPromoLoading"
              :disabled="!promo"
              :text="$t('profile.promo.apply')"
            />
          </div>

          <template #footer>
            <AppButton
              class="hidden tablet:block"
              :class="[cryptoAddress ? 'hidden' : 'hidden tablet:block']"
              v-if="currentActiveWallet"
              type="button"
              view="primary"
              :loading="isNonCryptoLoading"
              :text="
                $t('profile.wallet.deposit') +
                ` (${currentActiveWallet.currency})`
              "
              @click="handleNonCryptoFormSubmit"
            />
          </template>
        </AppForm>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect, watch, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useClipboard } from '@vueuse/core'

import { useProfileStore } from '@/stores/profileStore'
import { useInitialDataStore } from '@/stores/initialData'

import AppForm from '@/components/app/AppForm.vue'
import AppInput from '@/components/app/AppInput.vue'
import AppButton from '@/components/app/AppButton.vue'
import AppSpinner from '@/components/app/AppSpinner.vue'
import AppIcon from '@/components/app/AppIcon.vue'
import CopyIcon from '@/assets/svg-icons/copy.svg?url'
import ErrorFillIcon from '@/assets/svg-icons/error-fill.svg?url'

import { CurrencyWalletForSelect } from '@/types'
import WalletAndCurrencySelector, {
  PaymentMethodItem,
} from './shared/WalletAndCurrencySelector.vue'
import DepositBonuses from './shared/DepositBonuses.vue'

import { startNewBankDeposit } from '@/api/actions/startNewBankDeposit'
import { startNewPiastrixDeposit } from '@/api/actions/startNewPiasrixDeposit'

import { getRejectedRequestErrorMessage } from '@/api/utils'
import { startNewCryptoDeposit } from '@/api/actions/startNewCryptoDeposit'

import { useBonusesAvailable } from './composables/use-bonuses-available'
import { useI18n } from 'vue-i18n'
import { applyPromo } from '@/api/actions/applyPromo'
import { getDepositMethodsV2 } from '@/api/actions/getDepositMethodsV2'
import { useAuthStore } from '@/stores/auth'

import {
  PaymentMethodName,
  NetworkNames,
  NonCryptoNetworks,
  DecimalPlacesByCurrencyName,
} from './constants'
import { paymentMethodsMockIcons } from '@/utils/constants'
import { useAppStateStore } from '@/stores/appStateStore'
import { storeToRefs } from 'pinia'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'

const api = {
  startNewBankDeposit,
  startNewPiastrixDeposit,
  startNewCryptoDeposit,
  applyPromo,
  getDepositMethodsV2,
}

const route = useRoute()
const router = useRouter()

const isUseBonusesEnabled = !!route.query.autoAcceptBonuses
const { t } = useI18n({ useScope: 'global' })

const profileStore = useProfileStore()
const initialDataStore = useInitialDataStore()
const authStore = useAuthStore()
const { isQuickDepositDialogOpen } = storeToRefs(useAppStateStore())
const isMobileLayout = inject(IS_MOBILE_LAYOUT_KEY, ref(false))

const profileData = computed(() => {
  return profileStore.profileData!
})

const currencyWallets = computed<CurrencyWalletForSelect[]>(() => {
  return (
    profileData.value?.wallets.map((w) => ({
      ...w,
      mainCurrencyRecalculationBalance: !w.isMainCurrency
        ? `${w.mainCurrencyRecalculation!.balance} ${
            profileData.value?.mainCurrency.code
          }`
        : undefined,
    })) || []
  )
})

const currentActiveWallet = ref<typeof currencyWallets.value[number] | null>(
  null
)

const depositPaymentMethods = ref<PaymentMethodItem[] | null>(null)

const currentActivePaymentMethod = ref<PaymentMethodItem | null>(null)

watch(
  () => depositPaymentMethods.value,
  (value, oldValue) => {
    if (depositPaymentMethods.value) {
      if (oldValue) {
        currentActivePaymentMethod.value =
          depositPaymentMethods.value!.find((it) => {
            return (
              it.value.code === currentActivePaymentMethod.value!.value.code
            )
          }) || depositPaymentMethods.value![0]
      } else {
        currentActivePaymentMethod.value = depositPaymentMethods.value[0]
      }
    }
  }
)

const copyButtonText = computed<string>(() => {
  return `${t('profile.wallet.copy-address')} (${
    currentActivePaymentMethod.value?.name
  })`
})

const isSignedIn = computed(() => {
  return authStore.isSignedIn
})

const onUpdateWallet = (wallet: CurrencyWalletForSelect) => {
  currentActiveWallet.value = wallet
  getBonusesList(currentActiveWallet.value.currency)
  profileStore.onSelectedWalletInWalletAndCurrencySelector(wallet)
  getDepositPaymentMethods(currentActiveWallet.value.currency)
}
const setInitialSelectedWallet = () => {
  if (route.query.currency) {
    const foundedWallet = currencyWallets.value.find(
      (w) => w.currency === route.query.currency
    )
    if (foundedWallet) {
      onUpdateWallet(foundedWallet)
    }
  } else {
    currentActiveWallet.value =
      currencyWallets.value.find((w) => w.isDefault) || null
  }
}

// watch is necessary because currencyWallets can become a new Array
// (selected item must be an element of exact currencyWallets array)
watch(
  () => currencyWallets.value,
  () => {
    if (currentActiveWallet.value) {
      currentActiveWallet.value =
        currencyWallets.value.find(
          (item) => item.currency === currentActiveWallet.value?.currency
        ) || null
    }
  }
)

watch(
  () => currentActiveWallet.value,
  () => {
    getBonusesList(currentActiveWallet.value?.currency)
  }
)

const {
  bonusesListTransformed,
  isLoading: isBonusesLoading,
  getBonusesList,
} = useBonusesAvailable()

//#region Bank/Piastrix
const isNonCryptoLoading = ref(false)
const nonCryptoErrorMessage = ref('')
const nonCryptoAmountToDeposit = ref('')

const handleNonCryptoFormSubmit = async () => {
  isNonCryptoLoading.value = true
  try {
    const currencyCode = currentActiveWallet.value!.currency

    const currencyDecimalPlaces =
      initialDataStore.currencies.find((it) => it.code === currencyCode)
        ?.decimalPlaces ?? DecimalPlacesByCurrencyName[currencyCode]
    const amountInDecimals = Math.round(
      +nonCryptoAmountToDeposit.value * Math.pow(10, currencyDecimalPlaces)
    )

    const startDepositRequest =
      currentActivePaymentMethod.value?.value.code === 'BANK'
        ? api.startNewBankDeposit
        : api.startNewPiastrixDeposit

    const { url } = await startDepositRequest({
      amount: amountInDecimals,
      currencyCode,
    })

    // Using setTimeout is a workaround for iOS Safari issue https://stackoverflow.com/a/70463940
    setTimeout(() => {
      window.open(url, '_blank')
    })

    nonCryptoErrorMessage.value = ''
  } catch (e) {
    nonCryptoErrorMessage.value = getRejectedRequestErrorMessage(e)
  } finally {
    isNonCryptoLoading.value = false
  }
}
//#endregion Bank/Piastrix

//#region Crypto
const isCryptoLoading = ref(false)
const cryptoAddressLoadingError = ref('')
const cryptoAddress = ref<string>('')

const { copy: copyCryptoAddress, copied: isCryptoAddressCopied } = useClipboard(
  {
    source: cryptoAddress,
  }
)

watchEffect(async () => {
  if (currentActivePaymentMethod.value === null) return

  if (NonCryptoNetworks.includes(currentActivePaymentMethod.value.value.code)) {
    cryptoAddress.value = ''
    return
  }

  cryptoAddressLoadingError.value = ''
  cryptoAddress.value = ''

  try {
    isCryptoLoading.value = true
    const response = await api.startNewCryptoDeposit({
      asset: currentActivePaymentMethod.value.value.code,
      currencyCode: currentActiveWallet.value!.currency,
    })

    cryptoAddress.value = response.address
  } catch (e) {
    cryptoAddressLoadingError.value = ''
  } finally {
    isCryptoLoading.value = false
  }
})
//#endregion Crypto

//#region Promo
const isPromoLoading = ref(false)
const promo = ref('')
const promoErrorMessage = ref('')
const promoSuccessMessage = ref('')
const submitPromoForm = async (
  token?: string,
  forcePriorityCurrency?: string
) => {
  isPromoLoading.value = true
  try {
    await applyPromo(promo.value, token, forcePriorityCurrency)
    promoErrorMessage.value = ''
    promoSuccessMessage.value = t('profile.promo.success')
    refreshBonuses()
  } catch (e) {
    promoErrorMessage.value = getRejectedRequestErrorMessage(e)
  } finally {
    isPromoLoading.value = false
  }
}
const handlePromoFormSubmit = async () => {
  if (initialDataStore.gSiteKey) {
    // eslint-disable-next-line no-undef
    grecaptcha
      .execute(initialDataStore.gSiteKey, { action: 'submit' })
      .then((token) => submitPromoForm(token))
  } else {
    await submitPromoForm()
  }
}
const cleanPromoMessages = () => {
  promoErrorMessage.value = ''
  promoSuccessMessage.value = ''
}

const refreshBonuses = () => {
  getBonusesList(currentActiveWallet.value?.currency)
}
//#endregion Promo

const isDepositPaymentMethodsLoading = ref(false)
const depositPaymentMethodErrorMessage = ref('')
const getDepositPaymentMethods = async (currency: string) => {
  isDepositPaymentMethodsLoading.value = true
  try {
    const methods = await api.getDepositMethodsV2(currency)

    depositPaymentMethods.value = methods.data.map((it) => {
      return {
        name: PaymentMethodName[it.paymentMethod],
        value: {
          name: PaymentMethodName[it.paymentMethod],
          code: it.paymentMethod,
          minValue: it.minAmount,
        },
      }
    })
    depositPaymentMethodErrorMessage.value = ''
  } catch (e) {
    depositPaymentMethodErrorMessage.value = getRejectedRequestErrorMessage(e)
  } finally {
    isDepositPaymentMethodsLoading.value = false
  }
}

const filteredDepositPaymentMethods = computed(() => {
  return depositPaymentMethods.value?.filter(
    (paymentMethod) =>
      paymentMethod.value.code !== 'PIASTRIX_RUB' ||
      initialDataStore.configs?.FFFPIASTRIX === '1'
  )
})

const setActiveBonus = async () => {
  promo.value = route.query.promoCode as string
  await submitPromoForm(undefined, profileData.value.mainCurrency.code)
  promo.value = ''
  router.replace({
    query: {
      ...(route.query || {}),
      signUp: undefined,
      promoCode: undefined,
    },
  })
}

const init = async () => {
  if (route.query.promoCode && !isSignedIn.value) {
    authStore.goToSignUp()
  }
  if (route.query.promoCode && isSignedIn.value) {
    await setActiveBonus()
  }
  setInitialSelectedWallet()
  getDepositPaymentMethods(currentActiveWallet.value!.currency)
}

init()
</script>
