<template>
  <Listbox
    v-slot="{ open }"
    :class="{ 'opacity-40': disabled }"
    :disabled="disabled"
    :model-value="modelValue || undefined"
    as="div"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <ListboxLabel
      v-if="label"
      class="block text-ne-14 mb-ne-8"
      :class="{
        'text-ne-black': light,
        'text-ne-white': !light,
      }"
    >
      {{ label }}
    </ListboxLabel>
    <div class="relative">
      <ListboxButton
        class="relative w-full flex flex-nowrap items-center text-ne-14 px-ne-16 py-[12px] pr-[36px] text-left cursor-pointer disabled:cursor-auto border-2 border-ne-violet hover:border-ne-violet-light focus:border-ne-violet-light disabled:hover:border-ne-violet rounded-ne-s"
        :class="{
          'border-transparent': !border,
          'bg-ne-bg-4': background,
          'text-ne-black': light,
          'text-ne-white': !light,
          'border-ne-error': error,
          'hover:border-ne-error': error,
          'focus:border-ne-error': error,
        }"
      >
        <slot name="selectedItemIcon" :selected-item="modelValue"></slot>
        <slot name="selectButton" :selected-item="modelValue">
          <span
            class="block truncate"
            :class="{ 'text-ne-neutral': !modelValue?.name }"
          >
            {{ modelValue?.name || placeholder || '&nbsp;' }}
          </span>
        </slot>
        <span
          class="absolute inset-y-0 right-ne-16 flex items-center pointer-events-none"
        >
          <AppIcon
            :class="[open ? 'transform rotate-180' : '']"
            name="open-down-16"
            color="var(--ne-violet-light)"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="open"
          class="absolute z-10 mt-ne-8 w-full bg-ne-bg-4 text-ne-14 rounded-md py-ne-8 ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <ListboxOptions
            static
            class="max-h-60 overflow-y-auto"
            :class="{
              'bg-ne-white': light,
              'bg-opacity-100': light,
              'shadow-ne-s': light,
            }"
            :style="{
              maxHeight: listMaxHeight ? `${listMaxHeight}px` : undefined,
            }"
          >
            <ListboxOption
              v-for="(item, index) in items"
              :key="index"
              v-slot="{ active, selected }"
              as="template"
              :value="item"
            >
              <li
                class="cursor-pointer select-none relative block px-ne-16 py-[12px] pr-[36px]"
                :class="{
                  'bg-ne-violet bg-opacity-50': selected,
                  'bg-ne-violet bg-opacity-30': active && !selected,
                  'bg-ne-violet bg-opacity-20': light && selected,
                  'bg-ne-bg-4 bg-opacity-10': light && active && !selected,
                }"
              >
                <div class="flex items-center">
                  <slot name="optionIcon" :item="item">
                    {{ item.icon }}
                  </slot>
                  <slot :list-item="item" :is-selected="selected">
                    <span class="block truncate">
                      {{ item.name }}
                    </span>
                  </slot>
                </div>

                <span
                  v-if="selected"
                  class="absolute inset-y-0 right-ne-16 flex items-center"
                >
                  <AppIcon name="ok-16" color="r-var(--ne-success)" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
          <slot name="listFooter"> </slot>
        </div>
      </transition>
    </div>
    <p
      v-if="error && errorMessage"
      :id="`${name}-error`"
      class="mt-ne-10 text-ne-12 text-ne-error"
      aria-live="assertive"
    >
      {{ errorMessage }}
    </p>
    <p v-else-if="message" class="mt-ne-10 text-ne-12 text-gray-500">
      {{ message }}
    </p>
  </Listbox>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import AppIcon from './AppIcon.vue'

type ListItem = Record<'name' | 'value' | string, string | any>

export default defineComponent({
  name: 'AppSelect',

  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    AppIcon,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Object as PropType<ListItem[]>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<ListItem | null>,
      default: null,
    },
    light: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    background: {
      type: Boolean,
      default: false,
    },
    listMaxHeight: {
      type: [Number, String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],
})
</script>
