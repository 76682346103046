<template>
  <div>
    <LoginForm
      :loading="isLoginFormLoading"
      :error-message="loginFormErrorMessage"
      :disabled="isLoginFormDisabled"
      :hide-footer="isLoginFormDisabled"
      :submit-text="$t('dialogs.login.submitButton')"
      @submit="onSubmitLoginForm"
      @forgot-password="handleForgotPasswordClick"
    />

    <template v-if="isTwoFactorFormVisible">
      <h3
        class="text-ne-h3 font-bold text-center mb-ne-24 text-ne-black mt-ne-48"
      >
        Two-factor authentication
      </h3>
      <TwoFactorCodeForm
        theme-light
        hide-cancel
        accent-submit
        :submit-text="$t('dialogs.login.submitButton')"
        :loading="isTwoFactorOtpCheckInProgress"
        :error-message="twoFactorFormErrorMessage"
        @submit="onSubmitTwoFactorForm"
      />
    </template>

    <span
      v-if="!isTwoFactorFormVisible && !isMobileLayout"
      class="block mt-ne-16 text-center text-ne-14 text-ne-black"
    >
      {{ $t('dialogs.login.dontHaveAccount.text') }}
      <a class="text-ne-link" href="#" @click="isSignUpDialogOpen = true">
        {{ $t('dialogs.login.dontHaveAccount.linkText') }}</a
      >
    </span>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, watch } from 'vue'
import { RoutesNames } from '@/router'
import { getRejectedRequestErrorMessage } from '@/api/utils'
import { useAuthStore } from '@/stores/auth'
import { useProfileStore } from '@/stores/profileStore'
import { useRoute, useRouter } from 'vue-router'
import { IS_SIGNUP_DIALOG_OPEN_KEY } from '@/components/app-authentication/DataContext/IS_SIGNUP_DIALOG_OPEN_KEY'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import TwoFactorCodeForm, {
  EmittedTwoFactorCodeData,
} from '@/components/forms/TwoFactorCodeForm.vue'
import LoginForm, { EmittedLoginData } from '@/components/forms/LoginForm.vue'
import * as Sentry from '@sentry/vue'
import { storeToRefs } from 'pinia'
import { checkGoogle2faOtp } from '@/api/actions/checkGoogle2faOtp'
import { IS_SIGNIN_DIALOG_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_SIGNIN_DIALOG_VISIBLE_KEY'
import { IS_FORGOT_PASSWORD_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_FORGOT_PASSWORD_VISIBLE_KEY'
import { wait } from '@/utils/wait'

const isMobileLayout = inject(IS_MOBILE_LAYOUT_KEY, ref(false))

const router = useRouter()
const route = useRoute()

const { signIn } = useAuthStore()
const { refreshProfile } = useProfileStore()
const { profileData } = storeToRefs(useProfileStore())

const isOpen = inject(IS_SIGNIN_DIALOG_VISIBLE_KEY, ref(false))
const isSignUpDialogOpen = inject(IS_SIGNUP_DIALOG_OPEN_KEY, ref(false))
const isForgotPasswordDialogOpen = inject(
  IS_FORGOT_PASSWORD_VISIBLE_KEY,
  ref(false)
)

const isLoginFormLoading = ref<boolean>(false)
const loginFormErrorMessage = ref<string | null>(null)
const isLoginFormDisabled = ref<boolean>(false)

async function handleForgotPasswordClick() {
  isOpen.value = false
  if (!isMobileLayout.value) {
    await wait(300)
  }
  isForgotPasswordDialogOpen.value = true
}

const onSubmitLoginForm = async (data: EmittedLoginData) => {
  isLoginFormLoading.value = true
  try {
    const { needTwoFactor } = await signIn(data)

    loginFormErrorMessage.value = null

    if (!needTwoFactor) {
      await refreshProfile()
      isOpen.value = false
      if (route.query.promoCode) {
        router.push({
          name: RoutesNames.ProfileWalletDeposit,
          query: { promoCode: route.query.promoCode },
        })
      } else {
        location.reload()
      }
    } else {
      isLoginFormDisabled.value = true
      isTwoFactorFormVisible.value = true
    }
  } catch (e) {
    loginFormErrorMessage.value = getRejectedRequestErrorMessage(e)
  } finally {
    isLoginFormLoading.value = false
    if (profileData.value) {
      try {
        Sentry.setUser({
          email: profileData.value.email,
          id: profileData.value.id,
          username: String(profileData.value.nickname),
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}

const isTwoFactorFormVisible = ref<boolean>(false)
const isTwoFactorOtpCheckInProgress = ref<boolean>(false)
const twoFactorFormErrorMessage = ref<string | null>(null)

const onSubmitTwoFactorForm = async (data: EmittedTwoFactorCodeData) => {
  try {
    isTwoFactorOtpCheckInProgress.value = true

    await checkGoogle2faOtp({ oneTimePassword: data.code })
    await refreshProfile()

    isOpen.value = false

    location.reload()
  } catch (e) {
    twoFactorFormErrorMessage.value = getRejectedRequestErrorMessage(e)
  } finally {
    isTwoFactorOtpCheckInProgress.value = false
  }
}

watch(
  () => isOpen.value,
  () => {
    if (!isOpen.value) {
      setTimeout(() => {
        loginFormErrorMessage.value = null
        isLoginFormDisabled.value = false
        isTwoFactorFormVisible.value = false
        twoFactorFormErrorMessage.value = null
      }, 300)
    }
  }
)
</script>
