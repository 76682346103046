<template>
  <div class="flex">
    <div
      class="rounded-ne-m bg-ne-bg-3 w-full desktop-s:flex-shrink-0 desktop-s:mr-ne-24 desktop-s:w-[282px]"
    >
      <div class="flex flex-col px-ne-16 py-[20px] desktop-s:flex">
        <div class="text-ne-14 mb-ne-4 desktop-s:px-0 desktop-s:font-bold">
          {{ $t('profile.wallet.title') }}
        </div>

        <AppSelect
          :model-value="wallet"
          class="block flex-shrink-0"
          name="walletToDeposit"
          background
          :border="false"
          :items="wallets"
          @update:model-value="$emit('update:wallet', $event)"
        >
          <template #selectButton="{ selectedItem }">
            <div
              v-if="selectedItem"
              class="flex w-full justify-between items-center max-h-[24px]"
            >
              <div class="flex items-center justify-between w-full">
                <div class="flex">
                  <span class="text-ne-white text-ne-14 font-bold">{{
                    selectedItem.balance
                  }}</span>
                </div>
                <div class="flex items-center">
                  <img
                    class="w-[24px] h-[24px] mr-[4px]"
                    alt="coin"
                    :src="currencyImages24[selectedItem.currency]"
                  />
                  <span class="text-ne-white text-ne-14 font-bold"
                    >{{ selectedItem.currency }}
                  </span>
                </div>
              </div>
            </div>
          </template>
          <template #default="{ listItem }">
            <div class="flex items-center justify-between w-full max-h-[24px]">
              <div class="flex flex-col items-start">
                <span class="text-ne-white text-[14px] font-bold">{{
                  listItem.balance
                }}</span>
                <span
                  v-if="listItem.mainCurrencyRecalculationBalance"
                  class="text-ne-neutral text-[12px]"
                  >{{ listItem.mainCurrencyRecalculationBalance }}
                </span>
              </div>
              <div class="flex items-center">
                <img
                  class="w-[24px] h-[24px] mr-ne-4"
                  alt="coin"
                  :src="currencyImages24[listItem.currency]"
                />
                <span class="text-ne-white text-ne-14 font-bold"
                  >{{ listItem.currency }}
                </span>
              </div>
            </div>
          </template>
        </AppSelect>
      </div>
      <div
        class="desktop-s:w-full desktop-s:h-[2px] desktop-s:bg-ne-bg-4"
      ></div>
      <div
        class="flex flex-col px-ne-16 py-[20px] pt-0 desktop-s:pt-[20px] desktop-s:hidden"
      >
        <div class="text-ne-14 mb-ne-4 desktop-s:hidden">
          {{ $t('profile.wallet.payment-method') }}
        </div>
        <AppSelect
          :model-value="selectedPaymentMethod"
          class="block desktop-s:hidden"
          name="paymentMethod"
          label=""
          :disabled="isPaymentMethodsLoading"
          :items="paymentMethods"
          @update:model-value="$emit('update:paymentMethod', $event)"
        >
          <template #selectButton="{ selectedItem }">
            <div
              v-if="selectedItem"
              class="flex w-full justify-between items-center max-h-[24px]"
            >
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center">
                  <span class="text-ne-white text-ne-14 font-bold mr-ne-8"
                    >{{ selectedItem.name }},
                    {{ NetworkNames[selectedItem.value.code] }}</span
                  >
                  <div
                    class="w-[3px] h-[3px] bg-ne-neutral rounded-full mr-ne-8"
                  ></div>
                  <span class="text-ne-neutral text-ne-14"
                    >min {{ selectedItem.value.minValue }}</span
                  >
                </div>
              </div>
            </div>
          </template>
          <!--        <template #default="{ item }">-->
          <!--          <span class="block truncate">-->
          <!--            {{ item.name }}, {{ item.value }}-->
          <!--          </span>-->
          <!--        </template>-->
        </AppSelect>
      </div>
      <div
        :class="{
          'opacity-80 pointer-events-none': isPaymentMethodsLoading,
          'desktop-s:px-ne-16 desktop-s:py-[20px] desktop-s:grid desktop-s:gap-[12px] desktop-s:grid-cols-2':
            !isQuickDepositDialogOpen,
        }"
        class="hidden"
      >
        <template v-for="item of paymentMethods" :key="item.value">
          <div
            class="flex flex-col items-center justify-center tablet:w-full desktop-s:w-[115px] py-[10px] rounded-ne-s cursor-pointer transition-all group"
            :class="{
              'bg-ne-violet': paymentMethod.value === item.value,
              'bg-ne-bg-4 hover:bg-ne-violet':
                paymentMethod.value !== item.value,
            }"
            @click="$emit('update:paymentMethod', item)"
          >
            <div class="w-full flex items-center justify-center min-h-[30px]">
              <img
                class="mx-auto w-[90px] h-auto"
                alt="paymentMethod"
                :src="paymentMethodsLogosMap[item.value.code]"
              />
            </div>
            <span
              class="text-ne-12 block mt-ne-8 transition-all"
              :class="{
                'text-ne-white': paymentMethod.value === item.value,
                'text-ne-neutral group-hover:text-ne-white':
                  paymentMethod.value !== item.value,
              }"
            >
              min {{ item.value.minValue }}
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, watchEffect, ref } from 'vue'
import AppSelect from '@/components/app/AppSelect.vue'
import { currencyImages24 } from '@/utils/constants'

import imgVisaMaster from '@/assets/images/payments-logos/visa+master-1.png'
import imgEth from '@/assets/images/payments-logos/eth.png'
import imgBtc from '@/assets/images/payments-logos/btc.png'
import imgTetherErc from '@/assets/images/payments-logos/USDT_ERC.png'
import imgTetherTrc from '@/assets/images/payments-logos/USDT_TRC.png'
import imgTrc20 from '@/assets/images/payments-logos/usdc_trc.png'
import imgErc20 from '@/assets/images/payments-logos/usdc_erc.png'
import imgLtc from '@/assets/images/payments-logos/ltc.png'
import imgPiasrix from '@/assets/images/payments-logos/piastrix.png'

import { PaymentMethodCode } from '@/api/types'
import { NetworkNames } from '../constants'
import { useAppStateStore } from '@/stores/appStateStore'
import { storeToRefs } from 'pinia'

export interface CurrencyWalletsItem {
  currency: string
  balance: string
  isDefault: boolean
  withdrawable: string
  blockByBonuses: string
  isMainCurrency: boolean
  mainCurrencyRecalculation?: {
    balance: string
    withdrawable: string
    blockByBonuses: string
    bonusToGo: string
  }
}

export interface PaymentMethodSelectItemValue {
  code: PaymentMethodCode
  name: string
  minValue: string
}
export interface PaymentMethodItem {
  name: string
  value: PaymentMethodSelectItemValue
}

export default defineComponent({
  name: 'WalletAndCurrencySelector',

  components: {
    AppSelect,
  },

  props: {
    wallets: {
      type: Object as PropType<CurrencyWalletsItem[]>,
      required: true,
    },
    wallet: {
      type: Object as PropType<CurrencyWalletsItem>,
      required: true,
    },
    paymentMethods: {
      type: Object as PropType<PaymentMethodItem[]>,
      required: true,
    },
    isPaymentMethodsLoading: {
      type: Boolean,
      required: true,
    },
    paymentMethod: {
      type: Object as PropType<PaymentMethodItem>,
      required: true,
    },
  },

  emits: ['update:wallet', 'update:paymentMethod'],

  setup(props) {
    const paymentMethodsLogosMap: Record<PaymentMethodCode, string> = {
      BANK: imgVisaMaster,
      BTC: imgBtc,
      ETH: imgEth,
      TRX_USDT_S2UZ: imgTetherTrc,
      USDT_ERC20: imgTetherErc,
      LTC: imgLtc,
      USDC: imgErc20,
      TRX_USDC_6NU3: imgTrc20,
      PIASTRIX_RUB: imgPiasrix,
    }

    const selectedPaymentMethod = ref<
      typeof props.paymentMethods[number] | null
    >(null)

    watchEffect(() => {
      selectedPaymentMethod.value =
        props.paymentMethods.find(
          (it) => it.value === props.paymentMethod.value
        ) || null
    })

    const { isQuickDepositDialogOpen } = storeToRefs(useAppStateStore())

    return {
      paymentMethodsLogosMap,

      selectedPaymentMethod,

      imgVisaMaster,
      imgEth,
      imgBtc,
      imgTetherErc,
      imgTetherTrc,
      imgLtc,
      imgTrc20,
      imgErc20,
      currencyImages24,
      NetworkNames,

      isQuickDepositDialogOpen,
    }
  },
})
</script>
