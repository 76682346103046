<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton class="flex-nowrap items-center flex">
      <AppIcon
        :name="open ? 'close-24' : 'burger-24'"
        class="w-ne-24 h-ne-24 h-ne-24 w-ne-24 tablet:h-ne-24"
      ></AppIcon>
    </PopoverButton>

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="absolute top-[40px] w-[327px] tablet:w-[300px] desktop-s:w-[225px] z-10"
      >
        <div
          class="grid grid-cols-1 py-ne-8 justify-start bg-ne-bg-5 items-center rounded-ne-s overflow-hidden firefox:bg-opacity-60"
          @click="close"
        >
          <router-link
            :to="{ name: RoutesNames.Payments }"
            class="app-header__nav-menu-item"
          >
            <AppIcon name="money-16" color="r-var(--ne-white)" />
            <span class="w-full ml-ne-10">{{ $t('header.nav.payments') }}</span>
          </router-link>
          <router-link
            :to="{ name: RoutesNames.VipClubDescription }"
            class="app-header__nav-menu-item"
          >
            <AppIcon name="stars-16" color="var(--ne-white)" />
            <span class="w-full ml-ne-10">{{ $t('header.nav.vip-club') }}</span>
          </router-link>
          <router-link
            :to="{ name: RoutesNames.Bonuses }"
            class="app-header__nav-menu-item"
          >
            <AppIcon name="gift-16" color="var(--ne-white)" />
            <span class="w-full ml-ne-10">{{ $t('header.nav.bonuses') }}</span>
          </router-link>
          <router-link
            :to="{ name: RoutesNames.Faq }"
            class="app-header__nav-menu-item"
          >
            <AppIcon name="docs-16" color="r-var(--ne-white)" />
            <span class="w-full ml-ne-10">{{ $t('header.nav.faq') }}</span>
          </router-link>
          <router-link
            :to="{ name: RoutesNames.KolesaOtBossa }"
            class="app-header__nav-menu-item"
          >
            <AppIcon name="car-16" color="var(--ne-yellow)" />
            <span class="mx-ne-10 text-ne-yellow">{{
              $t('header.nav.tournament')
            }}</span>
            <div
              class="w-[72px] h-ne-24 bg-ne-yellow rounded-[4px] flex justify-center items-center"
            >
              <span class="text-ne-black text-ne-12">Exclusive</span>
            </div>
          </router-link>
          <router-link
            :to="{ name: RoutesNames.TermsAndConditions }"
            class="app-header__nav-menu-item"
          >
            <AppIcon name="docs-16" color="r-var(--ne-white)" />
            <span class="w-full ml-ne-10">{{
              $t('header.nav.terms-and-conditions')
            }}</span>
          </router-link>
          <router-link
            :to="{ name: RoutesNames.AboutUs }"
            class="app-header__nav-menu-item"
          >
            <AppIcon name="info-16" color="var(--ne-white)" />
            <span class="w-full ml-ne-10">{{ $t('header.nav.about-us') }}</span>
          </router-link>
          <router-link
            :to="{ name: RoutesNames.PrivacyPolicy }"
            class="app-header__nav-menu-item"
          >
            <AppIcon
              name="docs-16"
              color="r-var(--ne-white)"
              class="shrink-0"
            />
            <span class="w-full ml-ne-10">{{
              $t('header.nav.privacy-policy')
            }}</span>
          </router-link>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppIcon from '../app/AppIcon.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { RoutesNames } from '@/router'
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
  name: 'HeaderNavMenu',
  components: { Popover, PopoverButton, PopoverPanel, AppIcon },

  setup() {
    const authStore = useAuthStore()

    return { RoutesNames, authStore }
  },
})
</script>

<style scoped>
.app-header__nav-menu-item {
  @apply h-[40px]
  flex
  items-center
  px-[16px]
  hover:bg-ne-violet
  hover:bg-opacity-50
  active:bg-ne-violet
  text-left;
}
</style>
