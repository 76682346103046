<template>
  <div>
    <SignUpForm
      :loading="isSignUpFormLoading"
      :error-message="signUpFormErrorMessage"
      :submit-text="$t('dialogs.signUp.submitButton')"
      @submit="onSubmitSignUpForm"
    />
    <span
      v-if="!isMobileLayout"
      class="block mt-ne-16 text-center text-ne-14 text-ne-black"
    >
      {{ $t('dialogs.signUp.haveAccount.text') }}
      <a class="text-ne-link" href="#" @click="handleSignInClick">{{
        $t('dialogs.signUp.haveAccount.linkText')
      }}</a>
    </span>
  </div>
</template>

<script setup lang="ts">
import SignUpForm, {
  EmittedSingUpData,
} from '@/components/forms/SignUpForm.vue'
import { inject, ref, watch } from 'vue'
import { RoutesNames } from '@/router'
import { getRejectedRequestErrorMessage } from '@/api/utils'
import { useAuthStore } from '@/stores/auth'
import { useProfileStore } from '@/stores/profileStore'
import { useLocalStorageReactiveFields } from '@/composables/useLocalStorageReactiveFields'
import { useRoute, useRouter } from 'vue-router'
import { IS_SIGNUP_DIALOG_OPEN_KEY } from '@/components/app-authentication/DataContext/IS_SIGNUP_DIALOG_OPEN_KEY'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import { storeToRefs } from 'pinia'
import { IS_SIGNIN_DIALOG_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_SIGNIN_DIALOG_VISIBLE_KEY'
import { wait } from '@/utils/wait'

const isMobileLayout = inject(IS_MOBILE_LAYOUT_KEY, ref(false))

const router = useRouter()
const route = useRoute()

const authStore = useAuthStore()
const { isSignedIn } = storeToRefs(useAuthStore())
const { refreshProfile } = useProfileStore()
const { selectedLocale } = useLocalStorageReactiveFields()

const isOpen = inject(IS_SIGNUP_DIALOG_OPEN_KEY, ref(false))
const isSignInDialogOpen = inject(IS_SIGNIN_DIALOG_VISIBLE_KEY, ref(false))
const isSignUpFormLoading = ref<boolean>(false)
const signUpFormErrorMessage = ref<string | null>(null)

async function onSubmitSignUpForm(data: EmittedSingUpData) {
  isSignUpFormLoading.value = true

  try {
    await authStore.signUp({ ...data, locale: selectedLocale.value })
    await refreshProfile()

    isOpen.value = false

    signUpFormErrorMessage.value = null
    if (route.query.promoCode) {
      router.push({
        name: RoutesNames.ProfileWalletDeposit,
        query: { promoCode: route.query.promoCode },
      })
    }
  } catch (e) {
    signUpFormErrorMessage.value = getRejectedRequestErrorMessage(e)
  } finally {
    isSignUpFormLoading.value = false
  }
}

async function handleSignInClick() {
  isOpen.value = false
  await wait(300)
  isSignInDialogOpen.value = true
}

function redirectToSignUp(promoCode?: string) {
  isOpen.value = true
  router.replace({
    query: {
      ...(route.query || {}),
      signUp: '1',
      promoCode,
    },
  })
}

function redirectBack() {
  router.replace({
    query: {
      ...(route.query || {}),
      signUp: undefined,
      promoCode: undefined,
    },
  })
}

function redirectToProfileWalletDeposit(promoCode: string) {
  router.push({
    name: RoutesNames.ProfileWalletDeposit,
    query: { promoCode },
  })
}

watch(
  () => isOpen.value,
  (newValue) => {
    if (!newValue) {
      setTimeout(() => {
        signUpFormErrorMessage.value = null
      }, 300)
    }
  }
)

watch(
  () => route.query.signUp,
  (signUp) => {
    if (signUp) {
      isOpen.value = true
    }
  },
  {
    immediate: true,
  }
)

watch(
  () => isOpen.value,
  (isOpen) => {
    if (isOpen) {
      redirectToSignUp(route.query.promoCode as string)
    } else {
      redirectBack()
    }
  },
  { immediate: true }
)

watch(
  () => route.query?.promoCode,
  (promoCode) => {
    if (promoCode) {
      if (isSignedIn.value) {
        redirectToProfileWalletDeposit(promoCode as string)
      } else {
        redirectToSignUp(promoCode as string)
      }
    }
  },
  { immediate: true }
)

authStore.$onAction((actionContext) => {
  if (actionContext.name === 'goToSignUp') {
    redirectToSignUp()
  }
})
</script>
