<template>
  <div class="z-50" :class="{ 'fixed min-w-full bg-ne-bg-3': sticky }">
    <div
      class="flex flex-row flex-nowrap justify-between items-center h-[88px] text-ne-14 text-ne-white text-center ne-container px-[16px] tablet:px-ne-24"
    >
      <div class="flex flex-nowrap items-center">
        <HeaderNavMenu class="flex mr-ne-16"></HeaderNavMenu>
        <AppLogo />
      </div>

      <!--#region RIGHT CONTROLS-->
      <div class="flex flex-nowrap items-center">
        <LanguageSelector />
        <template v-if="isSignedIn">
          <NotificationPopover />
          <WalletSelector
            class="tablet:block tablet:min-w-[185px] mr-ne-16 desktop-s:mr-ne-24 flex-shrink-0"
          />
          <div
            v-if="!isOutOfCasino"
            class="hidden tablet:flex tablet-s:mr-ne-24"
            @click="toggleQuickDepositDialog"
          >
            <AppButton :text="$t('header.deposit')" view="accent" />
          </div>
          <router-link
            v-if="isOutOfCasino"
            :to="{ name: RoutesNames.Index }"
            class="hidden tablet-s:flex tablet-s:mr-ne-24"
          >
            <AppButton :text="$t('header.back-to-casino')" view="primary" />
          </router-link>
          <HeaderPopover
            :is-out-of-casino="isOutOfCasino"
            :is-in-game-page="isInGamePage"
            @sign-out="handleSignOut"
          ></HeaderPopover>
        </template>

        <div v-if="!isSignedIn" class="hidden tablet:flex tablet:flex-nowrap">
          <AppButton
            view="accent"
            :text="$t('header.sign-up')"
            class="mr-ne-24"
            @click="handleSignUpClick"
          />
          <AppButton
            view="secondary"
            :text="$t('header.login')"
            @click="handleSignInClick"
          />
        </div>
        <LogInPopover
          v-if="!isSignedIn"
          @sign-up="handleSignUpClick"
          @login="handleSignInClick"
        ></LogInPopover>
      </div>
      <!--#endregion RIGHT CONTROLS-->

      <!--#region DIALOGS-->
      <AppDialog
        v-model:visible="isSignUpDialogOpen"
        :title="$t('dialogs.signUp.title')"
      >
        <SignUp />
      </AppDialog>

      <AppDialog
        v-model:visible="isSignInDialogOpen"
        :title="$t('dialogs.login.title')"
      >
        <SignIn />
      </AppDialog>

      <AppDialog
        v-model:visible="isForgotPasswordDialogOpen"
        :title="$t('dialogs.forgotPassword.title')"
      >
        <ForgotPassword />
      </AppDialog>

      <AppDialog
        v-model:visible="isNewPasswordSentConfirmationDialogOpen"
        :title="$t('dialogs.forgotPasswordRequestSent.title')"
      >
        <NewPasswordSentConfirmation />
      </AppDialog>
      <!--#endregion DIALOGS-->
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue'

import AppButton from '../app/AppButton.vue'
import HeaderPopover from '@/components/AppHeader/HeaderPopover.vue'
import HeaderNavMenu from '@/components/AppHeader/HeaderNavMenu.vue'
import LogInPopover from '@/components/AppHeader/LogInPopover.vue'
import NotificationPopover from '@/components/AppHeader/NotificationPopover.vue'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth'

import { RoutesNames } from '@/router'
import WalletSelector from '@/components/WalletSelector.vue'
import LanguageSelector from '@/components/AppHeader/LanguageSelector.vue'
import SignUp from '@/components/app-authentication/SignUp/SignUp.vue'
import { IS_SIGNUP_DIALOG_OPEN_KEY } from '@/components/app-authentication/DataContext/IS_SIGNUP_DIALOG_OPEN_KEY'
import { IS_FORGOT_PASSWORD_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_FORGOT_PASSWORD_VISIBLE_KEY'
import { IS_SIGNIN_DIALOG_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_SIGNIN_DIALOG_VISIBLE_KEY'
import SignIn from '@/components/app-authentication/SignIn/SignIn.vue'
import ForgotPassword from '@/components/app-authentication/ForgotPassword/ForgotPassword.vue'
import { IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY } from '@/components/app-authentication/DataContext/IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY'
import NewPasswordSentConfirmation from '@/components/app-authentication/NewPasswordSentConfirmation/NewPasswordSentConfirmation.vue'
import { storeToRefs } from 'pinia'
import AppDialog from '@/components/app/AppDialog.vue'
import AppLogo from '@/components/AppLogo.vue'
import { useAppStateStore } from '@/stores/appStateStore'

defineOptions({
  name: 'AppHeader',
})

defineProps({
  sticky: {
    type: Boolean,
    default: false,
  },
})

const { signOut } = useAuthStore()
const { isSignedIn } = storeToRefs(useAuthStore())

const isSignInDialogOpen = ref(false)
const isSignUpDialogOpen = ref(false)
const isForgotPasswordDialogOpen = ref(false)
const isNewPasswordSentConfirmationDialogOpen = ref(false)
const { toggleQuickDepositDialog } = useAppStateStore()

provide(IS_SIGNIN_DIALOG_VISIBLE_KEY, isSignInDialogOpen)
provide(IS_SIGNUP_DIALOG_OPEN_KEY, isSignUpDialogOpen)
provide(IS_FORGOT_PASSWORD_VISIBLE_KEY, isForgotPasswordDialogOpen)
provide(
  IS_NEW_PASSWORD_SENT_CONFIRMATION_VISIBLE_KEY,
  isNewPasswordSentConfirmationDialogOpen
)

const route = useRoute()

const isOutOfCasino = computed(() => route.path.startsWith('/profile'))
const isInGamePage = computed(() => route.name === RoutesNames.Game)

function handleSignOut() {
  signOut()
}

function handleSignUpClick() {
  isSignUpDialogOpen.value = true
}

function handleSignInClick() {
  isSignInDialogOpen.value = true
}
</script>
