<template>
  <router-link :to="{ name: RoutesNames.Index }" @click="handleLogoClick">
    <AppIcon
      v-if="isMobileLayout"
      name="mobile-logo"
      width="84px"
      height="44px"
    />
    <template v-else>
      <AppIcon
        name="crypto-boss-white"
        class="w-[156px] h-[31px] hidden tablet:block tablet:max-w-[144px] tablet:h-[30px]"
      ></AppIcon>
      <AppIcon
        name="logo-icon-small"
        class="w-[32px] h-[40px] tablet:hidden"
      ></AppIcon>
    </template>
  </router-link>
</template>

<script setup lang="ts">
import { RoutesNames } from '@/router'
import { inject, ref } from 'vue'
import { IS_MOBILE_LAYOUT_KEY } from '@/layouts/DefaultLayout/MobileLayout/DataContext/IS_MOBILE_LAYOUT_KEY'
import AppIcon from '@/components/app/AppIcon.vue'
import { useRoute } from 'vue-router'

const isMobileLayout = inject(IS_MOBILE_LAYOUT_KEY, ref(false))
const route = useRoute()

function handleLogoClick() {
  if (route.name === RoutesNames.Index) {
    location.reload()
  }
}
</script>
